import React, { useState, useEffect } from 'react';
import Subscription from './Subscription';
import { Link } from 'react-router-dom'

const SubscriptionContainer = (param, androidData, isAndroid) => {

    useEffect(() => {
    }, []);

    return (
        <section className="categoryWrapper">
            <div className="_2vKa8"></div>
            <div className="container categoryHeadWrapper">
                <div className="categoryLinkWrapper">
                    <div>
                        <p className="text-head">Purchase Options</p>

                        <div className="subscribe-box">
                            <p style={{ fontSize: '15px', color: '#fff' }}><span style={{ fontSize: '15px', color: '#fff' }}><span style={{ fontSize: '15px', color: '#fff' }} ><span
                            ><p style={{ fontSize: '21px', color: ' #e50914', textAlign: 'center' }}><b>BUY (1 year) or RENT (1 week) a single title or SUBSCRIBE (monthly or yearly) to get Unlimited streaming access to Fantastic Entertainment’s collection of premium movies and series.</b></p></span>- Payment will be charged through your stripe or PayPal account on
                purchase confirmation.<br /></span></span><span >- Subscription will
                                auto-renew every month/year based on the package you have purchased unless you cancel at least 24 hours be
              for the end of the current subscription period.<br /></span><span >- You can
                                manage your subscription package and payments by going to your stripe/PayPal account settings after
              purchase.<br /></span></p>
                        </div>

                    </div>
                </div>
                <Subscription param={param.param}
                androidData={androidData}
                isAndroid={isAndroid} />
            </div>
            
        </section>
    );
};
export default SubscriptionContainer;