import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import './App.css';
import './App.scss';
import Fingerprint2 from 'fingerprintjs2';
import { getSessionId } from './Utils/utils';
import { service } from './Network/service';
import Layouts from "./Layouts/routes";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const history = useHistory();
  let urlParams = new URLSearchParams(window.location.search);
  let androidToken = urlParams.get('antkn');
  if (androidToken) {
    localStorage.removeItem("userId");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem('isLoggedIn');
    service.eraseCookie('userId');
    service.eraseCookie('userEmail');
  }
  if (window.requestIdleCallback) {
    requestIdleCallback(function () {
      Fingerprint2.get(function (components) {
        var values = components.map(function (component) { return component.value })
        var murmur = Fingerprint2.x64hash128(values.join(''), 31);
        localStorage.setItem('deviceId', murmur);
        service.getGeoInfo().then(response => {
          service.setCookie("country_code", response.countryCode, 30);
          service.setCookie("ipaddress", response.query, 30);
        })
      })
    })
  } else {
    setTimeout(function () {
      Fingerprint2.get(function (components) {
        var values = components.map(function (component) { return component.value })
        var murmur = Fingerprint2.x64hash128(values.join(''), 31);
        localStorage.setItem('deviceId', murmur);
        service.getGeoInfo().then(response => {
          service.setCookie("country_code", response.countryCode, 30);
          service.setCookie("ipaddress", response.query, 30);
        })
      })
    }, 500)
  }
  async function fetchData() {
    await service.getGeoInfo().then(response => {
      let currentLocation = {}
      currentLocation['country_name'] = response.country
      currentLocation['city'] = response.city
      currentLocation['latitude'] = response.lat
      currentLocation['longitude'] = response.lon
      currentLocation['IPv4'] = response.query
      currentLocation['state'] = response.region
      localStorage.setItem('currentLocation', JSON.stringify(currentLocation));
      if (service.getCookie('userId') != null && service.getCookie('guestUserId') != service.getCookie('userId'))
        service.analytics().then(response => {
        })
    }).catch((error) => {
      if (service.getCookie('userId') != null && service.getCookie('guestUserId') != service.getCookie('userId'))
        service.analytics().then(response => {
        })
    });

  }
  useEffect(() => {
    var currentURL = new URL(window.location.href);
    var key = currentURL.searchParams.get("key");
    if (!isAuthenticated) {
      if (service.getCookie('userId') == null) {
        service.getGuestUser().then(response => {
          if (response) {
            service.authenticate().then(response => {
              if (response.token) {
                setIsAuthenticated(true);
              }
            });
          }
        });
      } else
        service.authenticate().then(response => {
          if (response.token) {
            setIsAuthenticated(true);
          }
        });

      if (localStorage.getItem('currentLocation') == null) {
        fetchData();
      }
      getSessionId();
    }
  }, []);
  return (
    <div className="App">
      {
        isAuthenticated && (<Layouts />)
      }
    </div>
  );
}

export default App;
