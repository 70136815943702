import React, { useState, useEffect } from "react";
import "./Mark.scss";

const Mark = ({ image }) => {
  
  useEffect(() => {}, []);
  return (
    <div className="mark">
      {" "}
      <img className="markImg" src={image} alt="" />
    </div>
  );
};

export default Mark;
