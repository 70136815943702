import React, { useEffect, useState } from "react";
import Slider from "../NetflixSlider";
import { service } from '../../Network/service';
import { useHistory } from "react-router-dom";

const ShowDetails = (props) => {
  let history = useHistory();
  const [show, setShow] = useState({});
  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    let showId = urlParams.get("id");
    service.getShowDetails(showId).then((response) => {
      if (response.success === true) {
        let temp = response.data;
        temp.video_id = temp.videos[0].video_id;
        temp.video_duration = temp.videos[0].video_duration;
        temp.video_name = temp.videos[0].video_name;
        setShow(temp);
      } else {
        history.push('/404');
      }
    })


  }, []);

  return (
    <>
    <section className="cat">
      <div className="slider-frame showDetailAdj">
        {
          show.show_id && (
            <Slider index={0}>
            <Slider.Item movie={show} index={0} key={show.show_id}></Slider.Item>
          </Slider>
          )
        }
       
      </div>
    </section>
    </>
  );
};
export default ShowDetails;
