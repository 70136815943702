import React, { useState, useEffect } from 'react';
import Footer from '../Basic/Footer';
const AboutUs = () => {
	window.scrollTo(0, 0);
	useEffect(() => {
	}, []);
	return (
		<div>
			<div className="pageWrapper">
				<div className="topContainer">
					<div className="menuCloseJS closeMenuWrapper">
						<div id="content" className="site-content">
							<div id="primary" className="content-area">
								<main id="main" className="site-main">
									<article id="post-39" className="post-39 page type-page status-publish hentry">
										<div className="entry-content">
											<div className="vc_row wpb_row vc_row-fluid  bb_custom_1584031025503" >
												<div className="wpb_column vc_column_container vc_col-sm-12">
													<div className="vc_column-inner">
														<div className="wpb_wrapper">
															<div className=" fantastic-header ml-3">
																<div id="main-bg" className="main-bg" style={{ top: '0px' }}></div>
																<div className="header-content">
																	<div className="row">
																		<div className="col-md-1"></div>
																		<div className="col-md-10">
																			<h1 style={{letterSpacing: '2px', lineHeight: '2'}}>Fantastic Entertainment</h1> <p style={{letterSpacing: '2px', lineHeight: '2'}}>Fantastic Entertainment™ is a producer and distributor of exclusive premium
																			programming for television and digital media. We pride ourselves on working
																			with the top independent film-makers and TV channels in order to ensure that
																			our content offering is both unparalleled and broadcast, streamed or downloaded
																			to millions around the world. If you are a Content Buyer, please check out our
              latest titles or email us  <a  style={{ color: '#148AB7' }}> (info@ffimail.com)</a>
               &nbsp; for more info and screeners. If you are a film-maker interested in help with distribution of your programs to
              TV and digital platforms or help with funding a production project, head over
               to: <a style={{ color: '#148AB7' }} href="https://www.fantasticfilmsinternational.com/home/"> www.fantasticfilmsinternational.com</a> for more info.</p>
																		</div>
																		<div className="col-md-1"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>
									</article>
								</main>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default AboutUs;
