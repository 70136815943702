import React, { useState, useEffect } from "react";
import Slider from "../NetflixSlider";
import "./Search.scss";
import { service } from '../../Network/service';
import Footer from "../Basic/Footer";

const Search = (props) => {

  const [moreRows, setMoreRows] = useState([]);
  const [isSetMore, setIsSetMore] = useState(false);
  let isProcessing = false;
  useEffect(() => {
    if (props.location.search) {
      let apiResponse = null;
      setIsSetMore(false);
      const urlParams = new URLSearchParams(props.location.search);
      while (moreRows.length > 0) {
        moreRows.pop();
      }
      let key = urlParams.get("keyword");
      moreRows.push(
        <>
          <div className="ml-4 mb-2">
            <span className="title">Results for: </span>
            <h3 className="search-title">{key}</h3>
          </div>
        </>
      );
      var singleObj = [];
      while (isProcessing);
      service.getShows(key).then(response => {
        if (response.success === true && response.data.length > 0) {
          apiResponse = response;

          //  if (!isSetMore) {
          let rows = [];
          let rowCount = 1;
          let count = apiResponse.data.length / 5;
          if (apiResponse.data.length % 5 == 0) {
            rowCount = count;
          } else {
            rowCount = count + 1;
          }
          let tempLen = 0;
          for (let i = 1; i <= rowCount; i++) {
            if (tempLen + 1 < apiResponse.data.length) {
              rows.push(i * 5);
            } else {
              rows.push(apiResponse.data.length);
            }
            tempLen += 5;
          }

          let indexBigin = 0;
          isProcessing = true;
          rows.map((item, index) => {
            moreRows.push(
              <>
                <section className="cat">
                  <div className="slider-frame">
                    <Slider index={index} key={index}>
                      {apiResponse.data
                        .slice(indexBigin, item)
                        .map(
                          (show) => (
                            (show.logo = show.thumbnail),
                            (
                              <Slider.Item
                                movie={show}
                                index={index}
                                key={show.video_id}
                              ></Slider.Item>
                            )
                          )
                        )}
                    </Slider>
                  </div>
                </section>
              </>
            );
            if(index + 1 == rows.length) {
              isProcessing = false;
            }
            indexBigin += 5;
          });
          setIsSetMore(true);
        }
      });
      // }
    }
  }, [props.location.search]);

  return <>{moreRows}
  </>;
};
export default Search;
