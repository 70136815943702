import React, { useState, useEffect } from "react";

const WebTermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="menuCloseJS closeMenuWrapper">
        <div className="privacyTermsFlex privacyTermsColor">
          <div className="privacyTermsWidth" style={{ marginTop: "-80px" }}>
            <h1 className="privacyTermsHeading">
              Fantastic Entertainment Terms & Conditions
            </h1>
            {/* <div className="privacyTermsWrapper"> */}
            <div>
              <div className="privacyTermsContent">
                <p>
                  Here are our terms and conditions for use of our site by you.
                  These are important details which we recommend that you read
                  carefully:
                </p>
                <ul className="listposition">
                  <li>
                    Agreeing to these terms and conditions is your choice and
                    enables us to provide you with an improved user experience.
                  </li>
                  <li>You can choose to opt out from using our services.</li>
                  <li>
                    By continuing to receive and use our services, you consent
                    to our terms and conditions as explained in this document.
                  </li>
                </ul>
                <h5 className="subHeading" className="subHeading">
                  Terms of use
                </h5>
                <p>
                  This document is an electronic record in terms of the
                  Information Technology Act,, 2000 and Rules there under as
                  applicable and various other statutes. This electronic record
                  is generated by a computer system and does not require any
                  physical or digital signatures.
                </p>
                <p>
                  This is a legal and binding agreement between you, the user
                  ("user" or "you") of the services, as defined below, and
                  Fantastic Entertainment stating the terms that govern your use
                  of the site
                </p>
                <h5 className="subHeading" className="subHeading">
                  Description of service and acceptance of terms of use
                </h5>
                <p>
                  Fantastic Entertainment content and the partner content will
                  be made available to you through different modes, at the
                  discretion of Fantastic Entertainment , including via video on
                  demand basis for viewing at the user's discretion at a time
                  chosen by you and download for offline viewing by you.Please
                  note that the availability of, and your ability to access, the
                  content or some part of services,
                </p>

                <p>
                  (a) is subject to Fantastic Entertainment 's sole discretion
                  and
                </p>
                <p>
                  (b) may be dependent upon your geographical location and your
                  consent to our privacy policy, and
                </p>
                <p>
                  (c) is on the condition that not all content or services will
                  be available to all viewers.On account of the nature of the
                  internet, this site and the services may also be accessed in
                  variousgeographical locations; and you hereby agree and
                  acknowledge that you are accessing this site and availing of
                  the services, at your own risk, choice and initiative and you
                  agree and undertake to ensure that your use of the site and
                  the services complies with all applicable laws including the
                  local laws in your jurisdiction.Further, these services and
                  content may vary from place to place, time to time and device
                  to device and would be subject to various parameters such as
                  specifications, device, internet availability and speed,
                  bandwidth, delay etc. you agree and acknowledge that Fantastic
                  Entertainment may monitor or use certain technologies for
                  monitoring of activities including logging of your ip address
                  to ascertain and verify your geographical location.To
                  facilitate your viewership and access, the services can be
                  packaged by Fantastic Entertainment through different models
                  such as, content or services may be accessible(though some of
                  these alternatives may not be available in your geographical
                  location):
                </p>
                <p>
                  (a) free of charge which may include advertisements or
                  commercials or
                </p>
                <p>
                  (b) via subscription through payment of a subscription fee or
                </p>
                <p>
                  (c) a pay-per-view model with or without
                  advertisements/commercials or
                </p>
                <p>
                  (d) with a combination of the foregoing on the siteor other
                  alternatives as technology and delivery systems change
                </p>

                <p>
                  The service can be availed through platforms, software and
                  devices, which Fantastic Entertainment approves from time to
                  time, the current list for which is as set out below
                  ("compatible system").In some cases, whether a deviceis (or
                  remains) a compatible system may depend on software or
                  platform provided or maintained by the device manufacturer or
                  other third parties.As a result, devices that are compatible
                  system at one time may cease to be compatible system in the
                  future.
                </p>
                <p>The Fantastic Entertainment website works best with:</p>
                <ul className="listposition">
                  <li>latest version of google chrome</li>
                  <li>latest version of firefox</li>
                  <li>latest version of safari</li>
                  <li>microsoft windows xp or above / mac os 10.2 or above</li>
                  <li>latest version of adobe flash player</li>
                  <li>javascript and cookies enabled</li>
                </ul>

                <p>
                  The Fantastic Entertainment applications work best with a
                  minimum system requirement of:
                </p>

                <ul className="listposition">
                  <li>android v.6.0</li>
                  <li>ios 8</li>
                  <li>roku 5.2</li>
                </ul>

                <p>
                  Subject to compatibility, you may be able to access the
                  services through mobile phones, tablets and other ip based
                  connected devices or any compatible system offered by a
                  partner authorized by Fantastic Entertainment or its third
                  parties or agents("partner").
                </p>

                <h5 className="subHeading" className="subHeading">
                  Use of the services is subject to compliance with these terms
                  of use.
                </h5>

                <p>
                  Unless otherwise specified, the services areavailable for
                  individuals who have attained the age of majority in their
                  jurisdiction; in the case of United States, this service is
                  restricted to users who are aged 18 years or older. If you are
                  under the relevant age of majority in your jurisdiction, you
                  may only access the site and the services only in accordance
                  with applicable law which enables a minor in your jurisdiction
                  to access such a service.
                </p>
                <p>
                  Some content offered on the site may not be suitable for some
                  viewers and therefore viewer discretion is advised. Also, some
                  content offered on the site may not be appropriate for
                  viewership by children. parents and/or legal guardians are
                  advised to exercise discretion before allowing their children
                  and/or wards to access content on this website. Your access
                  toand use of the services is subject to these terms and
                  conditions, privacy policy and all applicable laws, rules, and
                  regulations.
                </p>

                <h5
                  className="subHeading"
                  className="subHeading"
                  style={{ fontStyle: "italic" }}
                >
                  PARTICIPATION IN ANY ACTIVITY ON THE SITE CONDUCTED BY
                  Fantastic Entertainment OR VIEWING OF CERTAIN CONTENT MAY BE
                  SUBJECT TO FURTHER TERMS AND CONDITIONS AS MAY BE NOTIFIED BY
                  Fantastic Entertainment FROM TIME TO TIME ON THE SITE OR
                  OTHERWISE.
                </h5>

                <h5 className="subHeading">Electronic communications</h5>

                <p>
                  When you use / avail the services or send any data,
                  information or communication to Fantastic Entertainment , you
                  agree and understand that you are communicating with Fantastic
                  Entertainment through electronic media/ records and you
                  consent to receive communications via electronic records from
                  Fantastic Entertainment periodically and as and when required.
                  Fantastic Entertainment will communicate with you by email or
                  any push or other message or electronic records on the email
                  address and or mobile number available with Fantastic
                  Entertainment or made available to Fantastic Entertainment
                  through a partner which will be deemed adequate service of
                  notice / electronic record.
                </p>
                <p>
                  You acknowledge that specifically consent to Fantastic
                  Entertainment or our respective affiliates and partners
                  contacting you using the contact information you have provided
                  to us at any time during your association with us for any
                  purpose including the following purposes:
                </p>
                <ul className="listposition">
                  <li>to obtain feedback regarding the service;</li>
                  <li>
                    to contact you for offering new products or services,
                    whether offered by us, or our respective affiliates or
                    partners.
                  </li>
                </ul>

                <p>
                  You acknowledge and agree that you may be contacted for the
                  above purposes, using any of the contact details provided by
                  you, including via emails, text messages or phone calls.
                </p>
                <p>
                  In the event you have indicated any 'do not disturb'
                  preferences to your telecom service provider, and registered
                  yourself on the national customer preference register / 'do
                  not disturb' or similar list, we request you to ensure that
                  your registered preferences allow for communications to be
                  sent to you in accordance with the above provisions.
                </p>
                <p>
                  The content contained in the services including but not
                  limited to on the site is protected by copyright, trademark,
                  patent, trade secret and other laws. You agree to only use and
                  avail yourself ofthe services and the site in accordance with
                  these terms and conditions and acknowledge and agree that any
                  other activity by you must require the written permission of
                  Fantastic Entertainment.
                </p>

                <h5 className="subHeading">Other terms and conditions</h5>
                <h5 className="subHeading">1. Intellectual property</h5>
                <p>
                  The intellectual property rights ("intellectual property
                  rights") in the Fantastic Entertainment app, underlying
                  company know-how, trade secrets and the company platform, and
                  material published on Fantastic Entertainment app, including
                  (but not limited to) games, contests, software,
                  advertisements, written content, photographs, graphics,
                  images, illustrations, marks, logos, audio or video clippings
                  and flash animation, is owned by companyor controlled or under
                  license by the company fromits partners, licensors and/or
                  associates. users and consequently you may not modify,
                  publish, transmit, participate in the transfer or sale of,
                  reproduce, create derivative works of, distribute, publicly
                  perform, publicly display, or in any way exploit any of the
                  materials or contentof Fantastic Entertainment either in whole
                  or in part without express written license from Fantastic
                  Entertainment . C
                </p>
                <h5 className="subHeading">
                  2. User conduct – You and that you will ensure that each
                  person that you permit to use our services or to whom you
                  grant access to your access code and password agree that you
                  will not:
                </h5>

                <p>
                  1. disrupt, overburden, or aid or assist in the disruption or
                  overburdening of any computer or server used to offer or
                  support company or the company services.
                </p>
                <p>
                  2. attempt to gain unauthorised access to the user accounts,
                  servers or networks connected to company services by any means
                  other than the user interface provided by company, including
                  but not limited to, by circumventing or modifying, attempting
                  to circumvent or modify, or encouraging or assisting any other
                  person to circumvent or modify, any security, technology,
                  device, or software that underlies or is part of company
                  services.
                </p>
                <p>
                  3. attempt to transmit content regarding services, products,
                  surveys, contests, pyramid schemes, spam, unsolicited
                  advertising or promotional materials, or chain letters.
                </p>

                <h5 className="subHeading">Subscription services</h5>

                <p>
                  Some of the content made available on the siteis available
                  only by registered users who have registered under a
                  subscription model ("subscription user").You may need to
                  register yourself on the site to avail these services on a
                  subscription model ("subscription")and make the nominated
                  payments.To become a subscription user, you may be required to
                  provide certain personal information to Fantastic
                  Entertainment and may be required to make payments
                  ("subscription fee") as per the applicable membership plan
                  through a debit card, credit card, internet banking or
                  through,e-prepaid wallet or other payment method accepted by
                  Fantastic Entertainment ("payment method(s)") that you opted
                  for during registration. Fantastic Entertainment will be using
                  third party payment gateways to process and facilitate the
                  payment of your subscription fee to Fantastic Entertainment.
                  Onsuccessful registration to the subscription, Fantastic
                  Entertainment willnotify you of the same. Fantastic
                  Entertainment may offer different kinds of subscription plans,
                  each of these subscription plans will be subject to different
                  limitations and restrictions and the cost of each of these
                  subscription plans may vary.
                </p>
                <p>
                  Fantastic Entertainment shall have the discretion to make
                  certain content that is a part of the subscription available
                  to you on more than one end user compatible system
                  concurrently. Fantastic Entertainment also has the discretion
                  to make certain content that is a part of the subscription
                  available to you on not more than one end user device
                  concurrently.Please ensure that you read the details in the
                  subscription plans carefully.
                </p>
                <h5 className="subHeading">Free trial</h5>
                <p>
                  Some of the subscription plans may start with a free trialwith
                  or without a conditional deposit.The free trial period may
                  last fora different period of time depending on which
                  subscription plan you have agreed to and the free trial period
                  may be changed as may be determined by Fantastic Entertainment
                  from time to time.Your eligibility for a free trial including
                  the time for such free trial and the content available to be
                  viewed during free trial period is at the discretion of
                  Fantastic Entertainment and such free trial can be modified,
                  suspended or terminated by Fantastic Entertainment at any
                  point of time without giving notice to you.
                </p>
                <p>
                  In case you are eligible for any free trial, Fantastic
                  Entertainment may charge you a conditional deposit or token
                  amount of money at the time of your registration for the
                  subscription plan. This token amount will be refunded to you
                  once Fantastic Entertainment receives the confirmation from
                  the third party payment gateway provider that your payment
                  method has been registered.
                </p>

                <h5 className="subHeading">Payments</h5>

                <p>
                  Fantastic Entertainment will automatically start billing as
                  per your selected payment method for the subscription plan
                  elected by you at the time of registration through the third
                  party payment gateway or immediately at the end of the free
                  trial period, if applicable unless you cancel the subscription
                  before the end of the free trial period.The cancellation of
                  the subscription can be done through the "my account" section
                  of the site.It may take a few days for your payment made to
                  Fantastic Entertainment to be reflected in your "my account"
                  section.
                </p>
                <p>
                  In case you cancel your subscription before the end of the
                  free trial period, you will not be entitled to any
                  compensation monetary or otherwise from Fantastic
                  Entertainment for the unutilized period of free trial.
                </p>
                <p>
                  You cannot change your payment method during the tenure of
                  your subscriptionwithout our prior consent. If your selected
                  payment method is no longer available or expires or your
                  payment method fails for whatsoever reason, you will be
                  responsible and liable for any uncollected amountsand any
                  costs and expenses incurred by us in collecting the
                  outstanding payments from youand Fantastic Entertainment
                  reserves the right to terminate the subscription offered to
                  you.
                </p>
                <p>
                  The subscription fee will be billed in advance at the
                  beginning of your subscription membership or at theend of free
                  trial period, as applicable and on each subsequent renewal
                  automatically (as per the subscription plan chosen by you)
                  unless and until you cancel your subscription or the
                  subscription is otherwise suspended or discontinued pursuant
                  to these terms.To see the commencement date for your next
                  renewal period or to cancel your subscription to the
                  subscription, you need to visit the "my account" section on
                  your profile page.On cancellation of subscription you will be
                  notified by Fantastic Entertainment.
                </p>
                <p>
                  Fantastic Entertainment will be automatically billing as per
                  your payment method for each subscription period on a monthly
                  in advance basis.However, if you change your membership plan,
                  this could result in changing the day on which you are billed
                  and the amount which you are billed. In case of change in the
                  subscription fee for a particular membership plan that is
                  already opted by you, Fantastic Entertainment will give you an
                  advance notice of these changes.
                </p>
                <p>
                  The subscription fees specified for each subscription plan are
                  inclusive of applicable taxes.
                </p>
                <p>
                  The subscription fees billed are non-refundable irrespective
                  of whether the subscription have been used or availed by you
                  or notunder any circumstances.
                </p>
                <p>
                  Any changes in the subscription plan opted by you is effective
                  only after the expiry of the current subscription period for
                  which you have already been billed. Accordingly , the
                  subscription as per any revised plan opted by you shall be
                  effective only after the expiry of the then current
                  subscription period.
                </p>
                <p>
                  Any request for change or cancellation in any subscription
                  plan prior to the expiration of the current subscription plan
                  period will not entail you with a refund for any portion of
                  the subscription fee paid by you for the unexpired period.
                </p>
                <p>
                  You also understand and acknowledge that Fantastic
                  Entertainment only facilitates the third party payment gateway
                  for processing of payments.This facility is managed by the
                  third party payment gateway provider and you are required to
                  follow all the terms and conditions and privacy policy of such
                  third party payment gateway provider.
                </p>

                <p>
                  You are responsible for the accuracy and authenticity of the
                  information provided by you, including the bank account
                  number/credit card details and any otherinformation requested
                  during the subscription process.You agree and acknowledge that
                  Fantastic Entertainment shall not be liable and in no way be
                  held responsible for any losses whatsoever, whether direct,
                  indirect,incidental or consequential, including without
                  limitation any losses due to delay in processing of payment
                  instruction or any credit card fraud
                </p>
                <p>
                  You can access the status of your subscription anytime through
                  the "my account" section on your profile page.
                </p>
                <p>
                  You can file any complaint related to payment processing on
                  the site and the same will be forwarded to the concerned third
                  party payment gateway provider fortheir response.It is not our
                  responsibility to take any further action.
                </p>
                <p>
                  Upon payment through any of the payment modes and confirmation
                  of receipt ofsuch payment from the payment gateway provider,
                  an invoice will be made available to you in the "my account"
                  section which can be downloaded by you in pdf format.
                </p>
                <p>
                  Upon payment being received by Fantastic Entertainment through
                  any of the payment method, Fantastic Entertainment shall make
                  the subscription available to you through any of the approved
                  modes of delivery that Fantastic Entertainment adopts.Systems
                  are used to ensure that your subscription commences promptly
                  however delays for various reasons may result arise and you
                  agree that we are not liable for any loss or claim arising
                  from such delays.
                </p>
                <p>
                  It is hereby clarified that the subscription offered to youis
                  offered by Fantastic Entertainment and not by any third party
                  including any payment gateway service providersunless in the
                  process of your activities you have entered information on the
                  site of third party.
                </p>
                <p>
                  Fantastic Entertainment reserves the right to change,
                  terminate or otherwise amend the subscription plans,
                  subscription fees for the subscription and billing cycles at
                  its sole discretion and at any time.Such amendments shall be
                  effective upon posting on the site and your continued use of
                  the service shall be deemed to be yourconsent and acceptance
                  of such amendments.
                </p>
                <p>
                  Fantastic Entertainment reserves the right to change,
                  supplement, alter or remove any of the content that is subject
                  to a subscription fee as it deems fit. Fantastic Entertainment
                  does not guarantee the availability of a specific content or a
                  minimum content that is subject to a subscription fee.
                </p>
                <p>
                  Post cancellation of your subscription, if you wish to
                  re-subscribe to the subscription, you may do so from your "my
                  account" pageor create a new account.
                </p>

                <h5 className="subHeading">Access and use of the services</h5>
                <p>Limited use:</p>
                <p>
                  You are given a limited, non-exclusive, non-transferable,
                  non-sublicensable, revocable permission to view the site and
                  avail the service during the subsistence of your account and
                  subscription with Fantastic Entertainment for the territory of
                  the world or limited territories as applicable in respect of
                  specified content and as set forth in these terms and
                  conditions and no right, title or interest in any content will
                  be deemed transferred to you.As explained in these terms and
                  conditions we can terminate or suspend your permission.
                </p>

                <h5 className="subHeading">
                  By agreeing to use/avail the services:
                </h5>

                <ul className="listposition">
                  <li>
                    you confirm and warrant that all the data and information
                    supplied by you when you register (if applicable) and
                    provided by you to Fantastic Entertainment and or any
                    Fantastic Entertainment s affiliates, distributors
                    ("registration data") is true, current, complete and
                    accurate in all respects.
                  </li>
                  <li>
                    you agree to promptly update your registration data, so that
                    your information remains true, current, complete, and
                    accurate at all times.
                  </li>
                  <li>
                    you acknowledge and agree that your registration data and
                    any other personal data collected by Fantastic Entertainment
                    is subject to Fantastic Entertainment’s privacy policy.For
                    more information, view the full privacy policy. Fantastic
                    Entertainment may collect information such as occupation,
                    language, pincode, state name, area code, unique device
                    identifier, location, and the time zone where the services
                    are used and may use these to target advertising and make
                    content recommendations.
                  </li>
                  <li>
                    you agree that certain other data may be collected /
                    recorded from your device via the site by Fantastic
                    Entertainment and third parties engaged by Fantastic
                    Entertainment , in accordance with the terms and for the
                    purposes specified in Fantastic Entertainment ‘s privacy
                    policywhich can be accessed at
                    <a
                      href="https://www.fantasticfilmsinternational.com"
                      style={{ color: "blue" }}
                    >
                      {" "}
                      https://www.fantasticfilmsinternational.com
                    </a>
                    .This data may be collected / recorded sometimes when the
                    site is in the background and will use data on your device,
                    for which you may be charged in accordance with your
                    applicable data/broadband tariff plans.
                  </li>
                  <li>
                    you agree that certain other data may be collected /
                    recorded from your device via the site by third parties
                    engaged by Fantastic Entertainment , in accordance with the
                    terms and for the purposes specified in Fantastic
                    Entertainment ‘s privacy policy.
                  </li>
                  <li>
                    you acknowledge, consent and agree that Fantastic
                    Entertainment may access, preserve, transfer and disclose
                    your account information and/or user material (see below)
                    subject to the privacy policy, if required to do so by law
                    to cooperate with mandated government and law enforcement
                    agencies or to any private parties by an order under law for
                    the time being in force to enforce and comply with the law
                    including to various tax authorities upon any demand or
                    request by them or in a good faith belief that such access,
                    preservation, or disclosure isreasonably necessary to: (a)
                    comply with legal process and/or any applicable law in the
                    territory; (b) enforce the terms and conditions; (c) respond
                    to your requests for customer service; or (d) protect the
                    rights, property or personal safety of Fantastic
                    Entertainment , affiliates, its users and the public.
                  </li>
                  <li>
                    you agree that Fantastic Entertainment has the right to
                    temporarily suspend access to the whole or any part of the
                    services for any technical/operational reason and shall be
                    under no liability to you in such an event. Fantastic
                    Entertainment may, but shall not be obliged to, give you as
                    much notice of any interruption of access to the services as
                    is reasonably practicable. Fantastic Entertainment will
                    restore access to the services as soon as reasonably
                    practicable after temporary suspension.
                  </li>
                  <li>
                    you agree that Fantastic Entertainment shall be under no
                    liability whatsoever to you in the event of non-availability
                    of the site or any portion thereof occasioned by act of god,
                    war, disease, revolution, riot, civil commotion, strike,
                    lockout, flood, fire, satellite failure, failure of any
                    public utility, man-made disaster, satellite failure or any
                    other cause whatsoever beyond the control of Fantastic
                    Entertainment .
                  </li>
                  <li>
                    you acknowledge and agree not to either directly or through
                    the use of any device, software, internet site, web-based
                    service, or other means remove, alter, bypass, avoid,
                    interfere with, violate, or circumvent any patent; trade
                    secret; copyright, trademark, or other proprietary notice
                    marked on the content or any digital rights management
                    mechanism or device; any content protection oraccess control
                    measure associated with the content, including geo-filtering
                    mechanisms; privacy; publicity; or other proprietary right
                    under applicable law.
                  </li>
                  <li>
                    you agree not to either directly or through the use of any
                    device, software, internet site, web-based service, or other
                    means copy, download, capture, reproduce, duplicate,
                    archive, distribute, upload, publish, modify, translate,
                    broadcast, perform, display, sell, transmit or retransmit
                    the content or create any work or material that is derived
                    from orbased on the content. this prohibition applies
                    regardless of whether such derivative works or materials are
                    sold, bartered, or given away.
                  </li>
                  <li>
                    you agree that Fantastic Entertainment and/or its affiliates
                    or licensors owns and/or retains all rights to the services
                    and the Fantastic Entertainment content throughout the
                    territory of the world in perpetuity. You further agree that
                    the Fantastic Entertainment content you access and view as
                    part of the services is owned or controlled by Fantastic
                    Entertainment , its affiliates and or its licensors. The
                    services and the Fantastic Entertainment content are
                    protected by copyright, trademark, design, patent, trade
                    secret, and other intellectual property laws.
                  </li>
                  <li>
                    you further agree that you shall not post, publish,
                    transfer, upload, transmit or submit any of the content,
                    including without limitation videos, images, comments, user
                    material (defined below) or articles, of the site on any
                    other website, webpage or software, whatsoever.
                  </li>
                  <li>
                    you agree that the content provided on the site is strictly
                    for your private viewing only and not for public exhibition
                    irrespective of whether the public is charged for such
                    exhibition or not. you hereby agree to refrain from further
                    broadcasting or making available the content provided on the
                    site to any other person(s), or in any manner communicate
                    such content to the public.
                  </li>
                  <li>
                    you agree that third parties and/or its affiliates or
                    licensors owns and/or retains all rights to the third party
                    content. the third party content are protected by copyright,
                    trademark, design, patent, trade secret, and other
                    intellectual property laws. In addition, you agree that you
                    will not:
                  </li>
                  <li>
                    except as provided in these terms (or otherwise expressly
                    provided by Fantastic Entertainment ), you may not copy,
                    download, stream capture, reproduce, duplicate, archive,
                    upload, modify, translate, publish, broadcast, transmit,
                    retransmit, distribute, perform, display,sell, frame or
                    deep-link, make available, or otherwise use any content
                    contained in the services. you further agree not to use the
                    services to:
                  </li>
                  <li>
                    post, upload or otherwise transmit or link to content that
                    is: unlawful; threatening; abusive; obscene; vulgar;
                    sexually explicit; pornographic or inclusive of nudity;
                    offensive; excessively violent; invasive of another's
                    privacy, publicity, contract or other rights; tortious;
                    false or misleading; defamatory; libellous; hateful; or
                    discriminatory;
                  </li>
                  <li>harass or harm another person;</li>
                  <li>exploit or endanger a minor</li>
                  <li>
                    impersonate or attempt to impersonate any person or entity;
                  </li>
                  <li>
                    violate any patent, trademark, trade secret, copyright,
                    privacy, publicity or other proprietary right;
                  </li>
                  <li>
                    introduce or engage in activity that involves the use of
                    viruses, software disabling codes, bots, worms, or any other
                    computer code, files or programs that interrupt, destroy, or
                    limit the functionality of any computer software or hardware
                    or telecommunications equipment, or otherwise permit the
                    unauthorized use of or access to a computer or a computer
                    network;
                  </li>
                  <li>
                    attempt to decipher, decompile, disassemble or reverse
                    engineer any of the software, applications and/or any
                    element comprising the site, the application for accessing
                    the content, or the Fantastic Entertainment services;
                  </li>
                  <li>
                    interfere with, damage, disable, disrupt, impair, create an
                    undue burden on, or gain unauthorized access to the
                    Fantastic Entertainment services, including Fantastic
                    Entertainment s servers, networks or accounts;
                  </li>
                  <li>
                    cover, remove, disable, manipulate, block or obscure
                    advertisements or other portions of the Fantastic
                    Entertainment services or the content;
                  </li>
                  <li>
                    delete or revise any information provided by or pertaining
                    to any other user of the Fantastic Entertainment servic
                  </li>
                  <li>
                    use technology or any automated system such as scripts,
                    spiders, offline readers or bots in order to collect or
                    disseminate usernames, passwords, email addresses or other
                    data from the Fantastic Entertainment services, or to
                    circumvent, delete, deactivate, decompile, reverse engineer,
                    disassemble or modify any security technology or software
                    that is part of the Fantastic Entertainment services or the
                    site;
                  </li>
                  <li>
                    send or cause to send (directly or indirectly) unsolicited
                    bulk messages or other unsolicited bulk communications of
                    any kind through the Fantastic Entertainment services. if
                    you do so, you acknowledge you will have caused substantial
                    harm to Fantastic Entertainment , but that the amount of
                    harm would be extremely difficult to measure. As a
                    reasonable estimation of such harm, and by way of liquidated
                    damages and not as a penalty, you agree to pay companythe
                    amount that we notify you based on the events and
                    circumstances being an amount not less than $100 AUDfor each
                    actual or intended recipient of such communication without
                    prejudice to any other rights of action that Fantastic
                    Entertainment may have against you in law or equity;
                  </li>
                  <li>
                    solicit, collect orrequest any personal information for
                    commercial or unlawful purposes;
                  </li>
                  <li>
                    use technology or other means to access, index, frame, or
                    link to the site (including the content) that is not
                    authorized by Fantastic Entertainment ;
                  </li>
                  <li>
                    engage in commercial activity (including but not limited to
                    advertisements or solicitations of business; sales;
                    contests; sweepstakes; creating, recreating, distributing or
                    advertising an index of any significant portion of the
                    content; or building a business using the content) without
                    Fantastic Entertainment’s prior written consent;
                  </li>
                  <li>
                    use technology or other means to access, index, frame, or
                    link to the site (including the content) that is not
                    authorized by Fantastic Entertainment;
                  </li>
                  <li>
                    access the site/application/service (including the content)
                    through any automated means, including "robots," "spiders,"
                    or "offline readers" (other than by individually performed
                    searches on publicly accessible search engines for the sole
                    purpose of, and solely to the extent necessary for, creating
                    publicly available search indices-but not caches or archives
                    of the site and excluding those search engines or indices
                    that host, promote, or link primarily to infringing or
                    unauthorized content);
                  </li>
                  <li>
                    use the services to advertise or promote competing services;
                  </li>
                  <li>
                    use the services in a manner inconsistent with any and all
                    applicable law;
                  </li>
                  <li>
                    attempt, facilitate, induce, aid and abet, or encourage
                    others to do any of the foregoing.
                  </li>
                  <li>
                    you are responsible for the security and proper use of your
                    unique user id and password that you enter while registering
                    on the site or on the partner's website or application and
                    must take all necessary steps to ensure that they are kept
                    confidential, used properly, and not disclosed to
                    unauthorized people.
                  </li>
                  <li>
                    you agree that your use of the services and the content may
                    give you the opportunity to view, publish, transmit, submit
                    posts and/or comment in accordance with these terms and
                    conditions. you agree that you will not misuse the content
                    and/or other user material you access and view as part of
                    the service, including without limitation, download/store
                    the content by illegal/non-permitted means, or infringe any
                    of Fantastic Entertainment s / or its licensor's / or third
                    party's copyright, trademark design, patent, and other
                    intellectual property including any rights of Fantastic
                    Entertainment or its licensors or any third party subsisting
                    in the content. you further agree that you will not
                    copy/record/edit/modify any of the content you access and
                    view as part of the service, for any purpose, and/or
                    copy/record/edit/modify any content including video, images
                    etc., by using any means including software, spyware, etc.,
                    for any purpose whatsoever.
                  </li>
                </ul>

                <p>
                  In case the site allows you to download or stream any of the
                  content therein, you agree that you will not use, sell,
                  transfer or transmit the content to any person or upload the
                  content on any other website, webpage or software, which may
                  violate/cause damages or injuries to any copyright or any
                  other rights of Fantastic Entertainment or its affiliates or
                  any third party including privacy rights, publicity rights,
                  and intellectual property rights.
                </p>
                <p>
                  You may encounter third party applications while using the
                  site including,without limitation, websites, widgets,
                  software, services that interact with the site. your use of
                  these third party applications shall be subject to such third
                  party terms of use or license terms. Fantastic Entertainment
                  shall not be liable for any representations or warranties or
                  obligations made by such third party applications to you under
                  contract or law.
                </p>

                <h5 className="subHeading">Termination of service</h5>
                <p>
                  Fantastic Entertainment reserves the right to immediately
                  terminate, suspend, limit, or restrict your account or your
                  use of the services or access to content at any time, without
                  notice or liability, if Fantastic Entertainment so determines
                  in its sole discretion, for any reason whatsoever, including
                  that you have breached these terms and conditions, the privacy
                  policy, violated any law, rule, or regulation, engaged inany
                  inappropriate conduct, provided false or inaccurate
                  information, or for any other reason. You hereby agree and
                  consent to the above and agree and acknowledge that Fantastic
                  Entertainment can, at its sole discretion, exercise its right
                  in relation to any or all of the above, and that Fantastic
                  Entertainment , its directors, officers, employees,
                  affiliates, agents, contractors, principals or licensors shall
                  not be liable in any manner for the same; and you hereby
                  agree, acknowledge and consent to the same.
                </p>

                <h5 className="subHeading">
                  User reviews, comments and other material
                </h5>
                <p>
                  You may have an opportunity to publish, transmit, submit, or
                  otherwise post (collectively, "post")and reviews or comments
                  (collectively, "user material").As it concerns user material,
                  without prejudice to your obligation to otherwise comply with
                  applicable laws during the course of using the services, you
                  agree to hereby comply with any and all applicable laws, as
                  well as any other rules and restrictions that may be set forth
                  herein or on the site or servicesand sure that any user
                  material is not abusive, prejudicial, bullying or otherwise
                  offensive.
                </p>
                <p>
                  You agree that Fantastic Entertainment shall have the right
                  but have no obligation, to monitor user material and to
                  restrict or remove user material that Fantastic Entertainment
                  may determine, in its sole discretion, is inappropriate or for
                  any other reason. you acknowledge that Fantastic Entertainment
                  reserves the right to investigate and take appropriate legal
                  action against anyone who, in Fantastic Entertainment s sole
                  discretion, violates these terms, including, but not limited
                  to, terminating their account, terminate, suspend, limit, or
                  use of the services or access to content and/or reporting such
                  user material, conduct, or activity, to law enforcement
                  authorities, in addition to any other available remedies under
                  law or equity.Inaddition to the restrictions set forth above,
                  you must adhere to the below terms and conditions and not
                  host, display, upload, modify, publish, transmit, update or
                  share any information /material/user material that:
                </p>
                <p>
                  (a) belongs to another person and to which you do not have any
                  right to;
                </p>
                <p>
                  (b) is grossly harmful, intimidatory, bullying, untruthful,
                  malicious, harassing, blasphemous, defamatory, obscene,
                  pornographic, paedophilic, libellous, invasive of another's
                  privacy, hateful, or racially, ethnically objectionable,
                  disparaging, relating or encouraging money laundering or
                  gambling, or otherwise unlawful in any manner whatsoever;
                </p>
                <p>(c) harms minors in any way;</p>
                <p>
                  (d) infringes any patent, trademark, design, copyright or
                  other proprietary rights;
                </p>
                <p>(e) violates any law for the time being in force;</p>
                <p>
                  (f) deceives or misleads the addressee about the origin of
                  such messages or communicates any information which is grossly
                  offensive or menacing in nature;
                </p>
                <p>
                  (g) impersonates another personor provides information created
                  by another person without proper attribution;
                </p>
                <p>
                  (h) contains software viruses or any other computer code,
                  files or programs designed to interrupt, destroy or limit the
                  functionality of any computer resource;
                </p>
                <p>
                  (i) threatens the unity, integrity, defence, security or
                  sovereignty of United States or any other country, friendly
                  relations with foreign states, or public order or causes
                  incitement to the commission of any cognizable offence or
                  prevents investigation of any offence or is insulting any
                  other nation.You alsorepresent and warrant that the posting of
                  your user
                </p>
                <p>
                  material does not violate any right of any party, including
                  privacy rights, publicity rights, and intellectual property
                  rights.
                </p>
                <p>
                  You acknowledge that the user material is not confidential and
                  that you have no expectation of privacy in itand once you have
                  posted the user information you grant to us an irrevocable
                  royalty free perpetual licence to access and publish the user
                  material in any manner that we in our discretion determine to
                  be appropriate.
                </p>
                <p>
                  In no event does Fantastic Entertainment assume any
                  responsibility or liability whatsoever for any user material,
                  and you agree to waive any legal or equitable rights or
                  remedies you may have against Fantastic Entertainment with
                  respect to such user material.
                </p>
                <p>
                  This site may contain articles contributed by several
                  individuals. the views are exclusively their own and do not
                  represent the views of Fantastic Entertainment , affiliates
                  and/ or its management. all liability in respect of the above
                  is excluded to the extent permitted by law including any
                  implied terms. California, USA law and jurisdiction applywith
                  respect to contents of this site.
                </p>
                <p>
                  If you have any complaints relating to any third party user
                  material or content uploaded on the site, then please contact:
                  info@ffimail.com your email, which must bear your digital
                  signature or a signed written complaint, should include the
                  following information:
                </p>

                <ul className="listposition">
                  <li>
                    details of the objectionable user material or content;
                  </li>
                  <li>details of the basis of the objection;</li>
                  <li>what relief is being requested;</li>
                  <li>
                    all other relevant information required by Fantastic
                    Entertainment to assess the complain
                  </li>
                </ul>

                <p>
                  Fantastic Entertainment shall on examination of the complaint
                  take all reasonable measures, including taking down the
                  objectionable user material expeditiously in accordance with
                  applicable law. You agree and acknowledge that Fantastic
                  Entertainment s responsibility in relation to objectionable
                  user material is limited to removing them post receipt of your
                  objections in accordance with these terms of use and Fantastic
                  Entertainment shall not be responsible or liable for any other
                  damages or losses incurred by you of any nature whatsoever.Any
                  request for information or complaints that you submit will be
                  considered by us through our internal complaints resolution
                  process and we will use our best endeavours to respond with a
                  decision within 45 days of your written communication to us.
                </p>

                <h5 className="subHeading">Linked destinations</h5>
                <p>
                  Part of this site/service contains links to third party sites
                  and third party content. you agree and acknowledge that
                  Fantastic Entertainment does not endorse or sponsor such third
                  party sites, content, advertising or other material on such
                  third party sites.
                </p>
                <p>
                  If Fantastic Entertainment provides links or pointers to other
                  websites or destinations, you should not infer or assume that
                  Fantastic Entertainment operates, controls, or is otherwise
                  connected with these other websites or destinations. when you
                  click on a link within the services.Fantastic Entertainment
                  will not warn you that you have left the services and are
                  subject to the terms and conditions (including privacy
                  policies) of another website or destination. In some cases, it
                  may be less obvious than others that you have left the
                  services and reached another website or destination. Please be
                  careful to read the terms of use and privacy policy of any
                  other website or destination before you provide any
                  confidential information or engage in any transactions. You
                  should not rely on these terms and conditions and/or privacy
                  policy to govern your use of another website or destination
                </p>
                <p>
                  Fantastic Entertainment is not responsible for the content or
                  practices of any website or destination other than the site,
                  even if it links to the site and even if the website or
                  destination is operated by a company affiliated or otherwise
                  connected with Fantastic Entertainment . By using the
                  services, you acknowledge and agree that Fantastic
                  Entertainment is not responsible or liable to you for any
                  content or other materials hosted and served from any website
                  or destinationother than the site.
                </p>

                <h5 className="subHeading">Advertising</h5>
                <p>
                  Fantastic Entertainment may integrate third party software
                  development kits (sdks) with the site to improve placement of
                  advertisements that may be better suited to users. Fantastic
                  Entertainment expressly disclaim any liability arising out of
                  the advertisements, usage or viewing of these products or
                  services advertised on our site or the (third party) content
                  made available / hosted on the third party sites.
                </p>
                <p>
                  Fantastic Entertainment takes no responsibility for
                  advertisements or any third party material posted on the site
                  nor does it take any responsibility for the products or
                  services provided by advertisers. Any dealings you have with
                  advertisers found while using the services are between you and
                  the advertiser, and you agree that Fantastic Entertainment is
                  not liable for any loss or claim that you may have against an
                  advertiser.
                </p>

                <h5 className="subHeading">Third party content</h5>
                <p>
                  Fantastic Entertainment 's role is limited to providing a
                  communication platform along with hosting services to third
                  parties, to enable the transmission of the third party content
                  directly from third parties to you. The third party content on
                  the site is directly uploaded onto the site by third parties
                  who avail of Fantastic Entertainment s hosting services,
                  without any intervention from Fantastic Entertainment in the
                  uploading / transmission process. Fantastic Entertainment ‘s
                  role is that of an 'intermediary' as defined under The
                  Information Technology Act, 2000 and the rules thereunderand
                  other applicable laws in your geographical locations, with
                  regard to the third party content. Being an intermediary,
                  Fantastic Entertainment has no responsibility and / or
                  liability in respect of any third party content on the site,
                  including for intellectual property rights infringement,
                  defamation, obscenity or any other violation under applicable
                  law.
                </p>
                <p>
                  Fantastic Entertainment does not endorse, market, advertise or
                  publicize any third party content on the site and is not
                  responsible or liable for any third party content. Fantastic
                  Entertainment s role is limited to notifying you of the
                  availability of the third party content on the site.
                </p>
                <p>
                  Fantastic Entertainment does not pre-screen thethird party
                  content and has no obligation to monitor any third party
                  content.Hence, Fantastic Entertainment does not have actual or
                  specific knowledge of any third party content on the site.
                  However Fantastic Entertainment at its discretion and in
                  accordance with applicable law maymonitor any third party
                  content and may remove any third party content from the site
                  if Fantastic Entertainment determines in its sole discretion
                  that such third party content is in violation of this
                  agreement or any applicable law. Fantastic Entertainment , at
                  its discretion, may review the third party content when, and
                  only when, complaints are received from you. such actions do
                  not in any manner negate or dilute Fantastic Entertainment’s
                  position as an intermediary or impose any liability on
                  Fantastic Entertainment with respect to third party content.
                </p>
                <p>
                  Fantastic Entertainment will consider all communications,
                  requests and suggestions sent by you and other members of the
                  public provided that such communications are sent in a bona
                  fide manner in good faith in the interest of the site and
                  public good. However Fantastic Entertainment is not under any
                  obligation to act on any such communications, requests and
                  suggestions or respond to anyone. Fantastic Entertainment s
                  decision in this respect shall be final. specifically, if any
                  such request relates to a request / demand to take down/
                  disable/ remove/ deleteany third party content in the site,
                  Fantastic Entertainment is under no legal obligation to
                  respond to or act on such requests. Fantastic Entertainment
                  will take such action as Fantastic Entertainment is required
                  to take under applicable law. If there is any valid court
                  order or administrative order issued requiring Fantastic
                  Entertainment to take any action, then Fantastic Entertainment
                  will comply with such court order or administrative order.
                </p>

                <h5 className="subHeading">Support</h5>
                <p>
                  If you have any questions, complaints or claims with respect
                  to the site and/or services and/or content, then such
                  correspondence should be directed to the address as given
                  below.
                </p>
                <p>
                  Fantastic Entertainment (c/o Fantastic Films International
                  LLC)
                </p>
                <p>
                  The contact email –
                  <span
                  style = {{textDecoration : "underline",color:"blue"}}
                    onClick={(e) => {
                      window.open("mailto:fantasticentertainment20@gmail.com");
                    }}
                  >
                    fantasticentertainment20@gmail.com
                  </span>
                </p>

                <h5 className="subHeading">Disclaimer of warranties</h5>
                <p>
                  You agree that use of the services and the access to the site
                  is at your own risk. The servicesincluding the site, the
                  content, the video player, user material and any other
                  materials contained on or provided through the site are
                  provided "as is" and, to the fullest extent permitted by law,
                  are provided without warranties of any kind, either express or
                  implied.Withoutlimiting the foregoing, Fantastic Entertainment
                  does not make any warranties of fitness for a particular
                  purpose, title, merchantability, completeness, availability,
                  security, compatibility or non-infringement; or that the
                  services will be uninterrupted, free of viruses, malware or
                  other harmful components, accurate, error free or reliable.
                </p>

                <h5 className="subHeading">Limitation of liability</h5>
                <p>
                  In no case shall Fantastic Entertainment , its directors,
                  officers, employees, affiliates, agents, contractors,
                  principals, or licensors be liable for any direct, indirect,
                  incidental, punitive, special, orconsequential damages arising
                  from your use of the services or any content or material
                  thereof or for any other claim related in any way to your
                  access of the site or use of the services or any content or
                  material thereof, including, but not limited to, any errors or
                  omissions in any content or material, or any loss or damage of
                  any kind incurred as a result of the use of the services or
                  any content or material thereof, even if advised of their
                  possibility. Fantastic Entertainment s monetary liability for
                  the services orthe content or material thereof on the site
                  shall be limited tothe total of the subscription payments that
                  you have paid during the preceding 12 months., where the laws
                  do not allow the exclusion or the limitation of liability for
                  consequential or incidental damages, in such jurisdictions,
                  Fantastic Entertainment s liability shall be limited to the
                  extent permitted by law.
                </p>

                <h5 className="subHeading">Indemnity</h5>
                <p>
                  You agree to defend, indemnify and hold harmless Fantastic
                  Entertainment , its affiliates, officers, directors, employees
                  and agents, from and against any and all claims, damages,
                  obligations, losses, liabilities, costs or debt, and expenses
                  (including but not limited to attorneys' fees) arising from:
                </p>

                <p>(i) your use of and access to the services;</p>
                <p>
                  (ii) your violation of any term of these terms and conditions;
                </p>
                <p>
                  (iii) your violation of any third party right, including
                  without limitation any publicity, privacy, or intellectual
                  property right;
                </p>
                <p>(iv) your breach of any applicable laws; and</p>
                <p>
                  (v) any unauthorized, improper, illegal or wrongful use of
                  your account by any person, including a third party, whether
                  or not authorized or permitted by you
                </p>
                <p>
                  This indemnification obligation will survive the expiry or
                  termination of these terms and conditions and your use of the
                  service.
                </p>

                <h5 className="subHeading">Governing law and jurisdiction</h5>
                <p>
                  The relationship between you and Fantastic Entertainment shall
                  be governed by the laws of California, USA without regard to
                  its conflict of law provisions and for resolution of any
                  dispute arising out of your use of the services.
                  notwithstanding the foregoing, you agree that :
                </p>
                <p>
                  (i) Fantastic Entertainment has the right to bring any
                  proceedings before any court/forum of competent jurisdiction
                  and you irrevocably submit to the jurisdiction of such courts
                  or forum; and
                </p>
                <p>
                  (ii) any proceeding brought by you shall be exclusively before
                  the courts at Los Angeles, CA, USA
                </p>
                <h5 className="subHeading">Severability</h5>
                <p>
                  If any provision of these terms and conditions is held
                  invalid, void, or unenforceable, then that provision shall be
                  considered severable from the remaining provisions, and the
                  remaining provisions given full force and effect.
                </p>

                <h5 className="subHeading">Changes</h5>
                <p>
                  Fantastic Entertainment reserves the right, at any time and
                  from time to time, without prior notice to you, to update,
                  revise, supplement, and otherwise modify these terms of use
                  and to impose new or additional rules, policies, terms, or
                  conditions on your use of the service.Any updates, revisions,
                  supplements, modifications, and additional rules, policies,
                  terms, and conditions (collectively referred to in this terms
                  of use as "revised terms") will be posted on the site and will
                  be effective immediately after such posting and thereafter
                  incorporated into these terms and conditions.We recommend that
                  you periodically check the site for the revised terms your
                  continued use of the services will be deemed to constitute
                  your acceptance of any and all such revised terms.
                </p>

                <h5 className="subHeading">Survival</h5>
                <p>
                  You acknowledge that your representations, undertakings, and
                  warranties and the clauses relating to indemnities, limitation
                  of liability, grant of license, governing law, confidentiality
                  shall survive the efflux of time and the termination of these
                  terms and conditions.
                </p>

                <h5 className="subHeading">Entire agreement</h5>
                <p>
                  These terms and conditions and privacy policy constitute the
                  entire agreement between you and Fantastic Entertainment
                  governing your use of the services, superseding any prior
                  agreements between you and Fantastic Entertainment regarding
                  such us
                </p>

                <h5 className="subHeading">Fantastic Entertainment</h5>
                <p>
                  &copy; 2020 Fantastic Entertainment. All Rights Reserved.
                  Fantastic Films International LLC - United States
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebTermsOfUse;
