import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './css/fantastic-grid-content.css';
import './css/fantastictv.css';
import './css/style.css';
import './css/newstyles.css';
import './css/bootstrap.css';
import './css/animate.css';
import './css/theme.css';
import './css/animate.css';
import 'material-icons/iconfont/material-icons.css';

import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import $ from 'jquery';
import Popper from 'popper.js';
import "./js/jquery.waterwheelCarousel.js"
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import { createStore } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import reducer from './store/reducer/reducer';
const store = createStore(reducer);

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();

