import React, { useState, useEffect } from "react";
import ReactHlsPlayer from "react-hls-player";
import { useHistory } from "react-router-dom";
import closepanel from "../../img/icon-closepanel.png";
import { service } from '../../Network/service';
import { convertAdUrl } from '../../Utils/utils';
import { useDispatch } from 'react-redux';
var details = [];
var videoDetailUtils = [];

const VideoDetails = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [closeBtn, setCloseBtn] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(
    <video id="content_video" className="video-js vjs-default-skin mainPlayer"
      controls preload="auto" > <source src="" type="video/mp4" /> </video>
  );
  const [videoDetails, setVideoDetails] = useState();
  useEffect(() => {
    window.scrollTo(0,0);
    if (props.location.state) {
      let show_details = '';
      let showId = '';
      if (history.location.state.movie.show_id) {
        showId = history.location.state.movie.show_id;
      }
      localStorage.setItem('showId', showId);
      service.getShowDetails(showId).then(response => {
        let dataDetails = response.data.videos;
        let showDetails = response.data
        dataDetails.map((item, index) => {
          if (item.video_id === history.location.state.movie.video_id) {
            show_details = item
          }
        })
        if (history.location.state.movie.premium_flag == '0') {
          show_details = dataDetails[0];
        }
        setVideoDetails(show_details);
        details = show_details;
        videoDetailUtils = showDetails;
        service.playerToken().then(tokenResponse => {
          let arr = show_details.video_name.split('/').reverse();
          let newURL = 'https://poppo.tv/playlist/playlist.m3u8?id=' + arr[1] + '&token=' + tokenResponse.data.data + '&type=video' + '&pubid='+ process.env.REACT_APP_PUBID;
          if (props.location.state.movie.type) {
            if (props.location.state.movie.type == 'trailor') {
              let arr1 = props.location.state.movie.teaser.split('/').reverse();
              newURL = 'https://poppo.tv/playlist/playlist.m3u8?id=' + arr1[1] + '&token=' + tokenResponse.data.data + '&type=trailer' + '&pubid='+process.env.REACT_APP_PUBID;
              // newURL = 'https://poppo.tv/playlist/playlist.m3u8?id=' + arr[1] + '&token=' + tokenResponse.data.data + '&type=trailer';
            }
          }
          let videoElem = 'content_video' + show_details.video_id + new Date().valueOf();
          setVideoPlayer(<video id={videoElem} className="video-js vjs-default-skin mainPlayer"
            controls preload="auto"
            autoPlay >
            <source src={newURL} type="application/x-mpegURL" />
          </video>)
          // show_details.ad_link = 'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=';
          let adUrl = convertAdUrl(show_details, videoDetailUtils);
          window.playMainPlayer(adUrl, videoElem, show_details.video_id, details);

        })
      })
    }

  }, []);
  window.onVideoPlay = (videoId, vd) => {
    setCloseBtn(true);
    let event = 'POP03';
    // service.checkVideoSubscription(videoId).then(response => {
    //   let videoDetails = response.data;
    //   if (videoDetails.premium_flag == 1 || videoDetails.payper_flag == 1 || videoDetails.rental_flag == 1) {
    //     service.checkUserSubscription().then(useResponse => {
    //       if (useResponse.data.length == 0) {
    //         let isLoggedIn = localStorage.getItem('isLoggedIn');
    //         if (isLoggedIn == 'false') {
    //           history.push({
    //             pathname: '/signin'
    //           });
    //         }
    //       }
    //     })
    //   } else {
    //   }
    // })




    service.onVideoPlayFunction(vd, event).then(response => {
    })
  }
  window.onVideoPause = (vd) => {
    let event = 'POP04';
    service.onVideoPlayFunction(vd, event).then(response => {
      //sd
    })
  }
  window.onVideoEnd = (vd) => {
    let event = 'POP05';
    service.onVideoPlayFunction(vd, event).then(response => {
      history.push({
        pathname: '/home'
      });
    })
  }
  const closeVideo = () => {
    if(details.length != 0) {
    window.onVideoPause(details);
    dispatch({ type: "SET_EXPAND", payload: false });
    history.goBack();
    }
  }
  return (
    <div>
      <div className="liveVideoWrapper">
        {videoPlayer}
      </div>
      {
      closeBtn && (<button className="close" id="close-single-page" onClick={() => closeVideo()}>
        <img
          src={closepanel}
          className="close-panel close-video"
          style={{ width: "40px" }}
        />
      </button>)
      }
    </div>
  );
};
export default VideoDetails;
