import React, { useState, useEffect } from "react";
import cx from "classnames";
import SliderContext from "./context";
import Content from "./Content";
import SlideButton from "./SlideButton";
import SliderWrapper from "./SliderWrapper";
import useSliding from "./useSliding";
import useSizeElement from "./useSizeElement";
import "./Slider.scss";
import { swipedetect, getDeviceType } from '../../Utils/utils';
import { useSelector, useDispatch } from 'react-redux';

const Slider = ({ children, activeSlide, index }) => {
  const dispatch = useDispatch();
  const currentExpand = useSelector((state) => state.currentExpand);
  const prvExpand = useSelector((state) => state.prvExpand);
  let path = window.location.pathname;
  path = path.length == 1 ? '/home' : path;
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const { width, elementRef } = useSizeElement();
  const {
    handlePrev,
    handleNext,
    slideProps,
    containerRef,
    hasNext,
    hasPrev,
    handleScrollNext,
    handleScrollPrev
  } = useSliding(width, React.Children.count(children));

  const handleSelect = (movie) => {
    if (currentExpand == null && prvExpand == null) {
      dispatch({ type: "SET_CURRENT_EXPAND", payload: index });
    }  else {
      let cur = currentExpand;
      dispatch({ type: "SET_CURRENT_EXPAND", payload: index });
      dispatch({ type: "SET_PRV_EXPAND", payload: cur });
    }
    setCurrentSlide(movie);
  };

  const handleClose = () => {
    setCurrentSlide(null);
  };

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    elementRef,
    currentSlide,
  };
  let viewed = 0;
  let totalInViewport = 0;
  let distance = 0;
  let containerWidth = 283;

  useEffect(() => {
    swipedetect(containerRef.current, function (swipedir) {
      if (swipedir == "left" && hasNext) {
        distance = distance - containerWidth;
        handleScrollNext(viewed++, ++totalInViewport, distance);
      } else if (swipedir == "right" && viewed != 0) {
        distance = distance + containerWidth;
        handleScrollPrev(viewed--, --totalInViewport, distance);
      }
    });
  }, []);
  useEffect(() => {
    if (index == prvExpand && prvExpand!=currentExpand) setCurrentSlide(null);
  }, [currentExpand,prvExpand, dispatch]);
  return (
    <SliderContext.Provider value={contextValue}>
      <SliderWrapper>
        <div className={cx("slider", { "slider--open": currentSlide != null })}>
          <div ref={containerRef} className="slider__container" {...slideProps}>
            {children}
          </div>
        </div>
        {hasPrev && getDeviceType() == 'desktop' && !path.startsWith("/more") && !path.startsWith("/newArrivals") && <SlideButton onClick={handlePrev} type="prev" />}
        {hasNext && getDeviceType() == 'desktop' && !path.startsWith("/more") && !path.startsWith("/newArrivals") && <SlideButton onClick={handleNext} type="next" />}
      </SliderWrapper>
      {currentSlide && (
        <Content
          index={index}
          key={currentSlide.show_id}
          movie={currentSlide}
          onClose={handleClose}
        />
      )}
    </SliderContext.Provider>
  );
};

export default Slider;
