import React, { useState, useEffect } from "react";
//import './PrivacyPolicy.css';

const WebPrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="menuCloseJS closeMenuWrapper">
        <div className="privacyTermsFlex privacyTermsColor privacyTermsScroll">
          <div className="privacyTermsWidth" style={{ marginTop: "-80px" }}>
            <h1 className="privacyTermsHeading">
              Fantastic Entertainment Privacy policy
            </h1>
            {/* <div className="privacyTermsWrapper"> */}
            <div>
              <div className="privacyTermsContent">
                <p>
                  Here are our privacy principles. These are important details
                  which we recommend that you read carefully:
                </p>
                <ul className="listposition">
                  <li>
                    Providing information to us is your choice and enables us to
                    provide you with an improved user experience.
                  </li>
                  <li>
                    You can choose to have the personal information provided by
                    you deleted.
                  </li>
                  <li>
                    You always have the ability to opt-out of receiving
                    communications from the site or apps.
                  </li>
                  <li>
                    By asking us to assist, you consent to the collection and
                    use of the information you provided to us as explained in
                    this document.
                  </li>
                </ul>

                <h5 className="subHeading">1. Introduction</h5>
                <p>
                  Fantastic Entertainment (“Our Platform” or “we” or “our”) is
                  operated by the American company Fantastic Films International
                  LLC in compliance with the California's Online Privacy
                  Protection Act (“the Act”),which regulates the collection,
                  storage, quality, use and disclosure and deletion of personal
                  information and sensitive information, sets out in this
                  Privacy Policy Statement how we handle your information
                  fairly, sensitively and securely and explains that you are
                  given certain rights in respect of this information.
                </p>
                <p>
                  This privacy policy covers the informationwe collect from the
                  user(s) (user or you or your) of &nbsp;
                  <a
                    href="https://fantasticfilmsinternational.com"
                    style={{ color: "blue" }}
                  >
                    https://fantasticfilmsinternational.com
                  </a>{" "}
                  (“website”) and any associated mobile sites, applications
                  (“Apps”) or the services provided by on the website (as
                  defined in the terms of use of the website –“terms of use”),
                  which services can be used or accessed on the site.
                </p>
                <p>
                  This privacy policy should be read in conjunction and together
                  with the terms of use. Personal information of a user is
                  collected if the user registers with the website, accesses the
                  website or takes any action on the website or uses or accesses
                  the services as defined in the terms of use. The terms
                  ‘personal information’, ‘sensitive personal data’ and
                  ‘information’ shall have the meaning ascribed to it under the
                  applicable laws in California, USA. Your use of our services
                  offered on the website is constructed to mean that you consent
                  to our collection, storage, processing and transfer of your
                  personal information or sensitive personal data or information
                  in accordance with this privacy policy and you consent to the
                  terms and conditions of this privacy policy.
                </p>

                <h5 className="subHeading">
                  2. Collection of information and use – you acknowledge and
                  agree:
                </h5>

                <ol className="listposition">
                  <li>
                    The personal information or sensitive personal data or
                    information which you may provide to the platform and/or
                    which we may collect as part of the information provided is
                    or could be the following: your registration details which
                    may include the name, email address, mobile/contact details,
                    age, gender, password, location, pin code, area code,
                    occupation, provided by youplus your billing, tax
                    registration and other information pertaining to your
                    transactions on the website or related to the
                    services.Further examples of information and activity that
                    we may collect are set out in this clause 2 and 3.
                  </li>
                  <li>
                    You may in some cases share your debit or credit card
                    details or other bank account details while accessing any
                    services offered on the site on a subscription model.
                  </li>
                  <li>
                    The information that we collect will be used to contact or
                    identify you, including allowing us to provide you
                    meaningful customersupport. If you would like to request
                    modification or deletion of certain personally identifiable
                    information, please contact us so that we will be able to do
                    necessary actions.
                  </li>
                  <li>
                    Your comments or other content that you publicly post or
                    share on the website.
                  </li>
                  <li>
                    If you access or engage with the website or services
                    associated with the website through a social media service,
                    we may collect your user id or user name, any information
                    you have permitted the social media service to share with
                    us, and any information you have made public with the social
                    media service.
                  </li>
                  <li>
                    Your usage behaviourof the website or interests, purchasing
                    behaviour, other traceable activities online, on apps and on
                    mobile such as destinations visited, viewed advertisements
                    or content consumed.
                  </li>
                  <li>
                    Details of the computer system or computer network which you
                    use to visit the website and undertake any activity on the
                    website, such as a unique device identifier including user
                    behaviourfrom web based cookies, session identifiers from
                    device id, commercially available services, data aggregator
                    platforms.
                  </li>
                  <li>User preferences (e.g., preferred language settings);</li>
                  <li>
                    Ip address, operating system, browser type, browser version,
                    browser configuration, name of internet service provider,
                    and other types of computer and connection related
                    information relevant to identifying your type of device,
                    connecting to the website, enabling data exchange with you
                    and your device, and ensuring a convenient use of the
                    website;
                  </li>
                  <li>
                    The uniform resource locator (URL) and ip address of the
                    website from which you accessed, or were directed to, our
                    website, including date and time;
                  </li>
                  <li>
                    Subpages visited while on our website, links followed on the
                    website, including date and time;
                  </li>
                  <li>
                    The full URLclickstream to, through and from the website,
                    including date and time;
                  </li>
                  <li>Service requests and orders placed;</li>
                  <li>
                    Transaction history, including open and completed
                    transactions;
                  </li>
                  <li>Search terms entered;</li>
                  <li>
                    Services/products viewed or searched for on the website;
                  </li>
                  <li>Shopping cart and payment information;</li>
                  <li>Credit card information;</li>
                  <li>
                    Information collected by cookies or similar technologies (as
                    described in the next paragraph);
                  </li>
                  <li>
                    Newsletter subscriptions, enrollment for promotions, use of
                    special offers, etc.
                  </li>
                  <li>Consents, authorizations, etc. granted;</li>
                  <li>
                    Survey answers, reviews, ratings and other types of feedback
                    provided;
                  </li>
                  <li>
                    Content of any communication sent through the website,
                    including any information posted in social communities on
                    the website or otherwise shared with platform and/or other
                    users, as well as chat messages and chat transcripts;
                  </li>
                  <li>
                    Information on software downloaded from the website; and
                  </li>
                  <li>
                    Any other information that you enter on, or upload to, the
                    website (e.g., content that you fill into an online form, a
                    photo that you upload).
                  </li>
                </ol>

                <p>
                  Our primary purposes in collecting information including
                  personal information or sensitive personal data or information
                  from you is to allow you to use the website and various
                  features and services on or in relation to the website or any
                  sites contact you for any services provided by the platform or
                  its affiliates or its various service providers or platform
                  business partners and advertisers; to record your information
                  and details as permitted and required under applicable laws,
                  statutory direction or judiciary orders; to serve various
                  promotion materials and advertising materials to you; and such
                  other uses as provided in the terms of use and this privacy
                  policy.
                </p>
                <p>
                  We may also use the personal information or sensitive personal
                  data or information provided by you or collected by the
                  platform for content recommendations, transactional emails or
                  to provide you with information, direct marketing, online and
                  offline advertising and other materials regarding products,
                  services and other offers from time to time in connection with
                  the platform or its parent, subsidiary and affiliated
                  companies ("platform entities") and its joint ventures.
                </p>
                <p>
                  We may also collect information or personal information to
                  track user behavior and preferences for internal analytics of
                  all users of the website. we may also use your information:
                  (i) to evaluate your eligibility for certain types of offers,
                  products or services; (ii) to perform analytics and conduct
                  customer research, including general market research or
                  surveying our customers' needs and opinions on specific
                  issues, generating sales and traffic patterns, and to analyze
                  advertising effectiveness, both on an anonymous basis (e.g.,
                  by aggregating data) or on an individual basis (if legally
                  permissible);
                </p>
                <p>
                  Fantastic Entertainment may integrate certain third party
                  software development kits (sdks) within the platform to assist
                  in placing advertisements better suited to you and improve the
                  overall user experience. such third party sdk's may directly
                  collect certain personal information from you on the platform,
                  such as user behavior, preferences, account activity, ip
                  address, user's interactions with advertisements and
                  identifiers for advertisers (idfas) and google advertising id
                  (aaid).
                </p>
                <p>
                  This personal information or sensitive personal data or
                  information is collected through the use of server log files
                  and tracking technologies to collect and analysecertain types
                  of technical information and may include cookies and web
                  beacons. if you do not consent to the use of cookiesand/or
                  device session tracking by the platform. You may at any time
                  adjust your browser settings and/or delete and/or disable the
                  cookies. However, please note that should you choose to
                  delete/ disable the use of cookies or other tracking
                  technologies on your computer, certain sections of the website
                  and/or services provided by the platform may not be available
                  to you or may provide a lesser user experience.
                </p>
                <p>
                  We may combine your personal information or sensitive personal
                  data or information, other information and information
                  collected from tracking technologies and aggregate it with
                  information collected from other users using our website to
                  attempt to provide you with the services and a better
                  experience on the website.
                </p>
                <p>
                  You understand, agree and acknowledgethat our collection,
                  storage and processing of your personal information or
                  sensitive personal data or information is for a lawful purpose
                  connected with a function or activity of the platform entities
                  and its joint ventures. YOU HEREBY CONSENT TO THE COLLECTION,
                  STORAGE, USE, DISCLOSURE, TRANSFER, AND DELETION OF THE
                  PERSONAL INFORMATION OR SENSITIVE PERSONAL DATA OR INFORMATION
                  BY PLATFORM FOR THE PURPOSES SET OUT IN THIS PRIVACY POLICY.
                  You further understand, agree and acknowledge that your
                  personal information which is classified as sensitive personal
                  data or information as per applicable laws is considered
                  necessary for the platform to provide various services on its
                  website to you and for your usage of the website and other
                  services provided by platform in relation to the website and
                  you affirmatively consent to its collection, storage, transfer
                  and processing.
                </p>

                <h5 className="subHeading">
                  3. Our Platformin-built featuresand App functionality
                </h5>
                <p>
                  We may collect your television viewership data and use it
                  along with other information that you provide us on the
                  platform, including any personal information, for the purposes
                  set out in this privacy policy i.e. to provide you with better
                  and personalised content, advertisements and suitable
                  recommendations.
                </p>
                <p>
                  We have integrated sdk in our platform that is designed to
                  measure and analysetelevision channel viewership of Fantastic
                  Entertainment users ("TV sdk").
                </p>
                <p>
                  The TV sdk initiated after obtaining relevant permissions,
                  uses the microphone on your device to capture audio samples
                  (including ambient sounds) and create encrypted fingerprint
                  files of such audio samples in real-time. the fingerprint
                  files are matched against a database of known fingerprints of
                  television channels and television content to identify and
                  understand your television viewership patterns. the
                  fingerprint files cannot be reverse engineered into audio
                  samples and are stored on your devices for a period not more
                  than 48 hours. No actual audio samples or voices including
                  conversations that you may have will be saved on your device
                  or transferred, at any point of time during the fingerprinting
                  process.
                </p>
                <p>
                  You may consent to the platform accessing your device
                  microphone and recording functions for the fingerprinting
                  process, as and when the pop-up appears on your device screen
                  requesting your explicit consent. You may at any time deny
                  such consent on the consent pop-up that appears from time to
                  time or by disabling the microphone functionality on your
                  device. Should you require assistance to revoke your consent
                  earlier given, please contact us at our coordinates given
                  towards the end of these terms of use.
                </p>

                <h5 className="subHeading">
                  4. Information sharing and disclosure
                </h5>
                <p>
                  Our Platformis the recipient of your personal information or
                  sensitive personal data or information and shall exercise
                  reasonable commercial endeavoursfor the prevention of
                  unauthorized disclosure and deletion of the personal
                  information including any sensitive personal data or
                  information provided by the users. we may enable access of
                  your personal information or sensitive personal data or
                  information to the platform entities, joint ventures, business
                  partners, agents or third parties for the purposes of the
                  services provided by them or for any other marketing related
                  activity undertaken by or on behalf of the platform entities
                  and/or its joint ventures, and/or for the purposes of
                  enhancing the quality of the services provided to you on the
                  website, and you expressly consent to the same.
                </p>
                <p>
                  We shall endeavourthat the third parties and agents engaged by
                  us to whom we may provide access to your personal information
                  or sensitive personaldata or information for the purposes set
                  out herein are under a contractual obligation to protect your
                  personal information or sensitive personal data or information
                  in accordance with the same standard as the platform has
                  agreed to be subject to in this privacy policyhowever the
                  third parties and agents may change their information
                  collection policies without our consent.
                </p>
                <p>
                  Our Platformmay disclose your personal information or
                  sensitive personal data or information to such extent as the
                  platform may deem necessary for you to enter into commercial
                  transactions with the website. Recipients of your personal
                  information or sensitive personal data may be located in any
                  country. This may include countries where applicable data
                  protection laws provide a lesser degree of protection than
                  your home country.
                </p>

                <h5 className="subHeading">
                  5. Compliance with laws and law enforcement
                </h5>
                <p>
                  Our Platformcooperates with mandated government and law
                  enforcement agencies or to any third parties by an order under
                  law for the time being in force to enforce and comply with the
                  law. We will also disclose any information including sensitive
                  personal data or information about you to government andlaw
                  enforcement agencies without your permission as we, in our
                  sole discretion, believe necessary or appropriate to respond
                  to valid claims and legal process, to protect the property and
                  rights of platform or a third party, to protect the safety of
                  the public or any person, or to prevent or stop any illegal,
                  unethical or legally actionable activity.Our Platform may also
                  provide your personal information including sensitive personal
                  data or information to various governmental authorities
                  including tax authorities upon any demand or request from
                  them.Any complaint that you submit will be considered by us
                  through our internal complaints resolution process and we will
                  use our best endeavours to respond with a decision within 45
                  days of you making the complaint.
                </p>

                <h5 className="subHeading">6. Business transfers</h5>
                <p>
                  Our Platformmay transfer or otherwise share some or all of its
                  assets, including your personal information including any
                  sensitive personal data or information, in connection with a
                  merger, acquisition, reorganization or sale of assets or in
                  the event of bankruptcy and you consent to the same. Should
                  such a sale or transfer occur, we will ensure that the
                  personal information including any sensitive personal data or
                  information you have provided through the website is stored
                  and used by the transferee in a manner that is consistent with
                  this privacy policy.
                </p>

                <h5 className="subHeading">7. Email policies</h5>
                <p>
                  Our Platform may use your personal information or sensitive
                  personal data or information for the purposes mentioned in
                  this privacy policy. You have full controlregarding which
                  emails you want to receive. If you decide at any time that you
                  no longer wish to receive such communications from the
                  platform entities, please follow the unsubscribe instructions
                  provided in any of the communications. Please note that once
                  we receive your request, it may take an additional period of
                  time for your opt-out to become effective.
                </p>

                <h5 className="subHeading">8. Data security</h5>
                <p>
                  We understand the importance of data security and we want your
                  user experience with us to be as safe as possible. We have
                  implementedreasonable safeguards and precautions to protect
                  your personal information or sensitive personal data or
                  information, including technical and organizational measures
                  against unauthorized access, improper use, alteration,
                  unlawful or accidental destruction, and accidental loss, both
                  in an online and offline context.
                </p>
                <p>
                  We have adopted reasonable security practices and procedure
                  and we maintain technical and organizational security measures
                  and procedures to protect the security of your personal
                  information or sensitive personal data or information, which
                  are consistent with measures used by other similar websites.
                </p>
                <p>
                  Please understand that, while we strive to protect your
                  personal information or sensitive personal data or information
                  against potential risks and exposures, there is no absolute
                  security in the online sphere. Hence, we ask you to support
                  ourIT security efforts by not disclosing any of your personal
                  information or sensitive personal data or information data on
                  our website that is particularly sensitive or not required in
                  the specific contextand regularly updating your password .
                  Furthermore, you can help us protecting your personal data by
                  signing off when you finish using login-protected areas of the
                  websiteand avoiding the use of an automatic log on system in
                  your device.
                </p>

                <h5 className="subHeading">9. Data retention</h5>
                <p>
                  We retain personal information or sensitive personal data or
                  information only for as long as we believe it to be necessary
                  for purposes set out herein for which the personal information
                  or sensitive personal data or information was
                  collected,subject to any legal requirements for the data to be
                  retained for longer periods of time
                </p>

                <h5 className="subHeading">10. Deleting your information</h5>
                <p>
                  If you wish to have the personal information or sensitive
                  personal data or information that you have provided to us
                  deleted, you can always do so by sending a request to us on
                  the e-mail id of our customer services department provided at
                  the bottom of this page. You may note that deletion of certain
                  personal information may lead to cancellation of your
                  registration with the site and/or your access to certain
                  features of the site can be restricted and may also lead to
                  complete cessation of your access to the site.
                </p>

                <h5 className="subHeading">
                  11. Links to other sites / financial data
                </h5>
                <p>
                  We provide links to other websites and portals such as third
                  party payment gateway providers for your convenience and
                  information (“other websites”). For instance, the platform
                  will be using third party payment gateway providers to process
                  and facilitate the payment of your subscription fee to the
                  platform for providing you access to the subscription. Please
                  note that the platform does not directly collect any financial
                  information such as credit card or debit card or net banking
                  details from you. Such other websites are not controlled by
                  usand may change their privacy policy at any time. When you
                  visit such other website you do so at your own risk. These
                  other websites may have their own privacy policies in place,
                  which we recommend you review if you visit any such other
                  websites. We do not assume any responsibility or liability for
                  such other websites, the content of such other websites and
                  their privacy practices, nor do we endorse them. We encourage
                  you to familiarize yourself with the privacy policy statements
                  provided by such other websites prior to providing them with
                  information about you or entering into any transactions on
                  them.
                </p>
                <p>
                  While transacting with these other websites, you may provide
                  your financial information including without limitation your
                  bank account details, credit card account details or your
                  details pertaining to any payment settlement or pre-paid
                  instrument service provider. You understand, agree and
                  acknowledge thatOur Platform never receives your financial and
                  payment information from these other websites. Your personal
                  information, sensitive personal data or information and
                  financial information will be dealt with by these other
                  websites in accordance with their respective privacy policies
                  and other terms and conditions andOur Platform shall not be
                  liable, accountable or responsible for your personal
                  information, sensitive personal data or information and
                  financial information which you provide to these other
                  websites.
                </p>

                <h5 className="subHeading">12. Security</h5>
                <p>
                  Our Platformuses commercially reasonable security practices
                  and procedures industry practices and procedures designed to
                  help keep your personal information or sensitive personal data
                  or information safe.Secure server software (ssl) encrypts all
                  information you submit before it is sent to the platform.
                  Furthermore, all of the user data we collect is protected
                  against unauthorized access. To prevent unauthorized access,
                  maintain data accuracy, and ensure correctuse of information,
                  we will employ commercially reasonable and practicable
                  security practices and procedures and security methods and
                  technologies. We will also ensure on reasonable commercial
                  efforts basis that any agent or third party that we employ
                  complies with the same security standards as we do for the
                  protection of your personal information or sensitive personal
                  data or information.
                </p>
                <p>
                  In the event any incident of breach may occur, we will
                  promptly notify and immediately begin remediation under
                  applicablelaws and regulations.
                </p>
                <p>
                  Your information may be transferred to or may be maintained on
                  computers, computer systems and computer resources located
                  outside of your state or country where the privacy laws may be
                  different (but no less stringent than the restrictions imposed
                  by California, USA law) as those where you live. Your
                  submission of such information represents your agreement to
                  that transfer.At the time of preparing this Privacy Policy, it
                  is the intention that we will retain your data on an
                  international server third party where data is to be stored in
                  various countries including United States and India but may
                  change from time to time.
                </p>
                <p>
                  Unfortunately, the transmission of information over the
                  internet is not completely secure.Although we strive to
                  protect your personal data, we cannot guarantee the security
                  of your data while it is being transmitted to our site; any
                  transmission is at your own risk. once we have received your
                  information, we have commercially reasonable procedures and
                  security practices in place to reasonably endeavourto prevent
                  unauthorized access.
                </p>
                <h5>13. Children's Privacy</h5>
                <p>
                  Our Services strictly comply with the Children’s Online
                  Privacy Protection Act (“COPPA”). We do not knowingly collect
                  personal identifiable information from children under 13. In
                  the case we discover that a child under 13 has provided us
                  with personal information, we immediately delete this from our
                  servers. If you are a parent or guardian and you are aware
                  that your child has provided us with personal information,
                  please contact us so that we will be able to do necessary
                  actions.
                </p>
                <h5 className="subHeading">14. Changes in privacy policy</h5>
                <p>
                  From time to time we may update this privacy policy.Your
                  continued use of our website and the services constitutes your
                  acceptance of the then-current privacy policy and terms of
                  use,so we encourage you to visit this page periodically to
                  review any changes.
                </p>

                <h5 className="subHeading">15. Phishing</h5>
                <p>
                  Identity theft and the practice currently known as "phishing"
                  are of great concern toOur Platform. Safeguarding information
                  to help protect you from identity theft is a top priority.WE
                  DO NOT AND WILL NOT, AT ANY TIME, REQUEST YOUR CREDIT CARD
                  INFORMATION OR NATIONAL IDENTIFICATION NUMBERS IN A NON-SECURE
                  OR UNSOLICITED E-MAIL OR TELEPHONE COMMUNICATION.
                </p>

                <h5 className="subHeading">16. Contact us</h5>
                <p>
                  If you have any questions about this privacy policy, the
                  practices of platform or your dealings with the website, you
                  can contact us at&nbsp;
                  <a
                    href="fantasticentertainment20@gmail.com"
                    style={{ color: "blue" }}
                  >
                    fantasticentertainment20@gmail.com
                  </a>
                </p>

                <h5 className="subHeading">Fantastic Entertainment</h5>

                <p>
                  &copy; 2020 Fantastic Entertainment. All Rights Reserved.
                  Fantastic Films International LLC - United States
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WebPrivacyPolicy;
