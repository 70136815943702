import React, { useEffect, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { useHistory } from "react-router-dom";
import { service } from "../../Network/service";
import removeMyList from "../../img/icon-addedmylist.png";
import addMyList from "../../img/icon-addmylist.png";
import disLikeIcon from "../../img/icon-dislike.png";
import disLikedIcon from "../../img/icon-disliked.png";
import likeIcon from "../../img/icon-like.png";
import likedIcon from "../../img/icon-liked.png";
import mute from "../../img/icon-mute.png";
import unmute from "../../img/icon-unmute.png";
import movieLogo from "../../img/movie-logo.png";
import playArrow from "../../img/play_arrow_white.png";
import EpiosdeSlider from "../EpisodeSlider";
import IconCross from "./../Icons/IconCross";
import Notification from "./../Basic/Notification";
import { getDeviceType } from "../../Utils/utils";
import { useSelector } from "react-redux";
import "./Content.scss";
import $ from "jquery";

const Content = ({ movie, onClose, index }) => {
  const [apiResponse, setApiResponse] = useState({ data: { videos: [] } });
  const currentExpand = useSelector((state) => state.currentExpand);
  const prvExpand = useSelector((state) => state.prvExpand);
  const [trailorPlayer, setTrailorPlayer] = useState(
    <video
      id="content_video"
      className="video-js vjs-default-skin mainPlayer"
      controls
      preload="auto"
    >
      {" "}
      <source src="" type="video/mp4" />{" "}
    </video>
  );
  const [trailorUrl, setTrailorUrl] = useState("");
  const [signInBlock, setSignInBlock] = useState(false);
  let path = window.location.pathname;
  path = path.length == 1 ? "/home" : path;
  const [likeFlag, setLikeFlag] = useState(null);
  const [disLikeFlag, setDisLikeFlag] = useState(null);
  const [myListFlag, setMyListFlag] = useState(null);

  const [isOverview, setIsOverview] = useState(true);
  const [isEpisode, setIsEpisode] = useState(false);
  const [isTrailer, setIsTrailer] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const [payPerView, setPayperView] = useState();
  const [rental, setRental] = useState();
  const [monthly, setMonthly] = useState();
  const [yearly, setYearly] = useState();
  let history = useHistory();

  const showOverview = (e) => {
    e.preventDefault();

    service.getShowDetails(movie.show_id).then((response) => {
      if (response.success === true) {
        service.playerToken().then((tokenResponse) => {
          let newURL = "";
          if (response.data.teaser) {
            let arr = response.data.teaser.split("/").reverse();
            newURL =
              "https://poppo.tv/playlist/playlist.m3u8?id=" +
              arr[1] +
              "&token=" +
              tokenResponse.data.data +
              "&type=trailer" +
              "&pubid=" +
              process.env.REACT_APP_PUBID;
          } else {
            newURL = "";
          }
          let videoElem = "content_video" + new Date().valueOf();
          setTrailorPlayer(
            <video
              id={videoElem}
              className="video-js vjs-default-skin trailerPlayer"
              controls
              preload="auto"
              autoPlay
            >
              <source src={newURL} type="application/x-mpegURL" />
            </video>
          );
          window.playTrailerPlayer(videoElem);
        });
      }
    });

    setIsOverview(true);
    setIsEpisode(false);
    setIsTrailer(false);
    setIsMore(false);
    setIsDetails(false);
  };

  const showEpisode = (e) => {
    setIsMute(false);
    setIsOverview(false);
    setIsEpisode(true);
    setIsTrailer(false);
    setIsMore(false);
    setIsDetails(false);
    if (getDeviceType() == "mobile") $(".content").css("height", 600 + "px");
  };

  const showTrailer = (e) => {
    e.preventDefault();
    setIsMute(false);
    setIsOverview(false);
    setIsEpisode(false);
    setIsTrailer(true);
    setIsMore(false);
    setIsDetails(false);
    if (getDeviceType() == "mobile") $(".content").css("height", 600 + "px");
  };

  const showMore = (e) => {
    e.preventDefault();
    setIsOverview(false);
    setIsEpisode(false);
    setIsTrailer(false);
    setIsMore(true);
    setIsDetails(false);
  };

  const showDetails = (e) => {
    e.preventDefault();
    setIsOverview(false);
    setIsEpisode(false);
    setIsTrailer(false);
    setIsMore(false);
    setIsDetails(true);
    setTimeout(() => {
      let contentHeight = $("#showDetails").height();
      if (contentHeight > 400) {
        $(".list-details-preview").css("bottom", "0px !important");
        let currentHeight = $(".content").css("height");
        let newheight =
          (contentHeight +
            Number(currentHeight.substr(0, currentHeight.length - 2))) /
          1.5;
        $(".content").css("height", newheight + "px");
      }
    }, 1000);
  };

  const likeVideo = (e) => {
    e.preventDefault();
    if (service.getCookie("isLoggedIn") == "true") {
      service.likeVideo(movie.show_id, 1).then((response) => {
        if (response.success === true) {
          setLikeFlag(1);
          movie.liked_flag = 1;
        }
      });
    } else {
      setSignInBlock(true);
      setTimeout(function () {
        setSignInBlock(false);
      }, 2000);
    }
  };

  const dislikeVideo = (e) => {
    e.preventDefault();
    if (service.getCookie("isLoggedIn") == "true") {
      service.disLikeVideo(movie.show_id, 1).then((response) => {
        if (response.success === true) {
          setDisLikeFlag(1);
          movie.disliked_flag = 1;
        }
      });
    } else {
      setSignInBlock(true);
      setTimeout(function () {
        setSignInBlock(false);
      }, 2000);
    }
  };

  const unLikeVideo = (e) => {
    e.preventDefault();
    service.likeVideo(movie.show_id, 0).then((response) => {
      if (response.success === true) {
        setLikeFlag(0);
        movie.liked_flag = 0;
      }
    });
  };

  const unDislikeVideo = (e) => {
    e.preventDefault();
    service.disLikeVideo(movie.show_id, 0).then((response) => {
      if (response.success === true) {
        setDisLikeFlag(0);
        movie.disliked_flag = 0;
      }
    });
  };

  const myListAdd = (e) => {
    e.preventDefault();
    if (service.getCookie("isLoggedIn") == "true") {
      service.addToMyPlayList(movie.show_id, 1).then((response) => {
        if (response.success === true) {
          setMyListFlag(1);
          movie.watchlist_flag = 1;
        }
      });
    } else {
      setSignInBlock(true);
      setTimeout(function () {
        setSignInBlock(false);
      }, 2000);
    }
  };

  const myListRemove = (e) => {
    e.preventDefault();
    service.addToMyPlayList(movie.show_id, 0).then((response) => {
      if (response.success === true) {
        setMyListFlag(0);
        movie.watchlist_flag = 0;
        if (path.startsWith("/watchList")) {
          window.location.reload();
        }
      }
    });
  };

  const onMute = (e) => {
    e.preventDefault();
    // let playerStream = document.getElementById('singleVideo');
    // if (playerStream !== null && playerStream !== 'undefined') {
    //   if(isMute) {
    //     playerStream.removeAttribute('muted');
    //   } else {
    //     playerStream.setAttribute('muted', true);
    //   }
    // }
    // setTrailorPlayer(
    //   <ReactHlsPlayer
    //    id='singleVideo'
    //     url={trailorUrl}
    //     autoplay={true}
    //     controls={true}
    //     width="100%"
    //     height="auto"
    //     muted={!isMute}
    //   />
    // );
    if ($("video").prop("muted")) {
      $("video").prop("muted", false);
    } else {
      $("video").prop("muted", true);
    }
    setIsMute(!isMute);
  };

  const onPlayVideo = (e) => {
    e.preventDefault();
    if (movie.single_video == 0 && !path.startsWith("/search")) {
      showEpisode({});
    } else {
      localStorage.setItem("saved" + path + index, JSON.stringify(movie));
      localStorage.setItem("videoPath", path);
      var supportPageOffset = window.pageXOffset !== undefined;
      var isCSS1Compat = (document.compatMode || "") === "CSS1Compat";
      var x = supportPageOffset
        ? window.pageXOffset
        : isCSS1Compat
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft;
      var y = supportPageOffset
        ? window.pageYOffset
        : isCSS1Compat
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
      const scrollPosition = [x, y];
      localStorage.setItem(
        "scrollPosition" + path,
        JSON.stringify(scrollPosition)
      );
      if (
        movie.premium_flag == 1 ||
        movie.rental_flag == 1 ||
        movie.payper_flag == 1
      ) {
        service.videoSubscription(movie.video_id).then((response) => {
          let videoDetails = response.data;
          let subFlag = true;
          let uId = 280;
          let user_id = service.getCookie("userId");
          if (user_id) {
            uId = user_id;
          }

          service.userSubscription(uId).then((useResponse) => {
            var userData = useResponse.data;
            videoDetails.map(function (subscription, index) {
              // if (useResponse.forcibleLogout === false) {
              if (useResponse.data.length == 0 && subFlag) {
                subFlag = false;
                service.setCookie("showId", movie.show_id, 10);
                service.setCookie("videoId", movie.video_id, 10);
                history.push({
                  pathname: "/subscription",
                  state: {
                    videoData: movie.video_id,
                  },
                });
              } else {
                let subscribedVideo = userData.filter(
                  (e) => e.sub_id == subscription.publisher_subscription_id
                );
                if (
                  subscribedVideo.length == 0 &&
                  index + 1 < videoDetails.length
                ) {
                  return;
                }
                if (
                  subscribedVideo.length == 0 &&
                  subFlag &&
                  index + 1 == videoDetails.length
                ) {
                  subFlag = false;
                  service.setCookie("showId", movie.show_id, 10);
                  service.setCookie("videoId", movie.video_id, 10);
                  history.push({
                    pathname: "/subscription",
                    state: {
                      videoData: movie.video_id,
                    },
                  });
                } else if (subFlag) {
                  subFlag = false;
                  history.push({
                    pathname: "/videoDetails",
                    state: { movie: movie },
                  });
                }
              }
            });
          });
        });
      } else {
        history.push({
          pathname: "/videoDetails",
          state: { movie: movie },
        });
      }
    }
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    localStorage.removeItem("saved" + path + index);
    onClose();
  };

  const getVideoDuration = (video_duration) => {
    if (!video_duration) {
      video_duration = 0;
    }

    var dateObj = new Date(video_duration * 60 * 1000);
    var hours = dateObj.getUTCHours();
    var minutes = dateObj.getUTCMinutes();
    var seconds = dateObj.getSeconds();

    var timeString =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0");
    return timeString;
    // var measuredTime = new Date(null);
    // var hours = '00';
    // var minutes = '00';
    // var seconds = '00';

    // measuredTime.setSeconds(video_duration); // specify value of SECONDS
    // var MHSTime = measuredTime.toISOString().substr(11, 8);
    // var MHSTimeArr = MHSTime.split(':');
    // var hours = MHSTimeArr[0];
    // var minutes = MHSTimeArr[1];
    // var seconds = MHSTimeArr[2];
    // return hours + 'h ' + minutes + 'm ' + seconds + 's'
  };

  useEffect(() => {
    setLikeFlag(movie.liked_flag);
    setDisLikeFlag(movie.disliked_flag);
    setMyListFlag(movie.watchlist_flag);
  }, [movie]);

  useEffect(() => {
    var singleObj = [];
    if (!movie.thumbnail) {
      movie.thumbnail = movie.logo;
    }
    if (!movie.video_duration) {
      movie.video_duration = movie.duration;
    }

    service.getShowDetails(movie.show_id).then((response) => {
      if (response.success === true) {
        if (!movie.video_id) {
          movie.video_id = response.data.videos[0].video_id;
        }
        if (!movie.video_name) {
          movie.video_name = response.data.videos[0].video_name;
        }
        if (!movie.premium_flag) {
          movie.premium_flag = response.data.premium_flag;
        }
        if (!movie.single_video) {
          movie.single_video = response.data.single_video;
        }
        if (!movie.rating) {
          movie.rating = response.data.rating;
        }
        service.videoSubscription(movie.video_id).then((response) => {
          response.data.map((item) => {
            if (item.subscription_type_name === "Pay Per View") {
              setPayperView(item);
            } else if (item.subscription_type_name === "Rental") {
              setRental(item);
            } else if (item.subscription_type_name === "Yearly") {
              setYearly(item);
            } else if (item.subscription_type_name === "Monthly") {
              setMonthly(item);
            }
          });
        });
        setApiResponse(response.data);
        service.playerToken().then((tokenResponse) => {
          let newURL = "";
          if (response.data.teaser) {
            let arr = response.data.teaser.split("/").reverse();
            newURL =
              "https://poppo.tv/playlist/playlist.m3u8?id=" +
              arr[1] +
              "&token=" +
              tokenResponse.data.data +
              "&type=trailer" +
              "&pubid=" +
              process.env.REACT_APP_PUBID;
          } else {
            newURL = "";
          }
          setTrailorUrl(newURL);
          console.log("newURL", newURL);
          let videoElem = "content_video" + new Date().valueOf();
          setTrailorPlayer(
            <video
              id={videoElem}
              className="video-js vjs-default-skin trailerPlayer"
              controls
              preload="auto"
              playsInline={true}
              autoPlay
              muted={isMute}
            >
              <source src={newURL} type="application/x-mpegURL" />
            </video>

            // <ReactHlsPlayer
            //   id="singleVideo"
            //   url={newURL}
            //   autoplay={true}
            //   controls={true}
            //   width="100%"
            //   height="auto"
            //   // muted={isMute}
            // />
          );
          window.playTrailerPlayer(videoElem);
        });
        if (
          path.startsWith("/showDetails") &&
          localStorage.getItem("episodeFlag" + movie.show_id) == "true"
        ) {
          showEpisode({});
          localStorage.removeItem("episodeFlag" + movie.show_id);
        } else if (
          localStorage.getItem("selectEpisode" + path + index) == "true"
        ) {
          showEpisode({});
          localStorage.removeItem("selectEpisode" + path + index);
        }
      }
    });
    setTimeout(() => {
      if (getDeviceType() == "mobile")
        if ($(".content__area__container").height() < 600) {
          if (movie.synopsis && isOverview) {
            // $(".content").css("height", 810 + "px");
          } else {
            $(".content").css("height", 600 + "px");
          }
        }
    }, 1000);
  }, []);
  const onShowDetailsExpand = () => {
    if (!path.startsWith("/showDetails"))
      history.push({
        pathname: "/showDetails",
        search: "?id=" + movie.show_id,
      });
  };
  return (
    <div
      className={isTrailer || isEpisode ? "content tabHeightAdj" : "content"}
    >
      {getDeviceType() === "desktop" ? (
        <div className="content__background">
          <div className="content__background__shadow" />
          {isOverview ? (
            <div className="content__background__image">{trailorPlayer}</div>
          ) : null}
        </div>
      ) : null}

      <div className="content__area">
        {getDeviceType() === "mobile" ? (
          <div className="content__background" style={{ height: "27%" }}>
            {isOverview ? (
              <div
                className="content__background__image"
                style={{ height: "27%" }}
              >
                {trailorPlayer}
              </div>
            ) : null}
          </div>
        ) : null}
        <div
          className={
            movie.teaser != null && isOverview
              ? "content__area__container trailorHeight"
              : "content__area__container"
          }
          style={
            getDeviceType() === "mobile" && path.startsWith("/search")
              ? { marginTop: "200px" }
              : null
          }
        >
          <div
            className="content__title"
            onMouseEnter={(e) => (e.target.style.color = "#e50914")}
            onMouseLeave={(e) => (e.target.style.color = "white")}
            style={{ cursor: "pointer" }}
            onClick={onShowDetailsExpand}
          >
            {movie.show_name}
          </div>
          {signInBlock === true ? <Notification /> : null}
          <div className="accordion-preview-expand-info " id="accordionExample">
            <ul
              className={
                movie.teaser != null
                  ? "list-inline text-center list-details-preview tabAdj"
                  : "list-inline text-center list-details-preview"
              }
            >
              <li
                className={
                  isOverview ? "list-inline-item active" : "list-inline-item"
                }
                onClick={showOverview}
              >
                Overview
              </li>
              {movie.single_video == 0 && !path.startsWith("/search") && (
                <li
                  className={
                    isEpisode ? "list-inline-item active" : "list-inline-item"
                  }
                  onClick={showEpisode}
                >
                  Episodes
                </li>
              )}
              {movie.teaser != null && (
                <li
                  className={
                    isTrailer ? "list-inline-item active" : "list-inline-item"
                  }
                  onClick={showTrailer}
                >
                  Trailers & More
                </li>
              )}

              {movie.show_name && movie.synopsis && (
                <li
                  className={
                    isDetails ? "list-inline-item active" : "list-inline-item"
                  }
                  onClick={showDetails}
                >
                  Details
                </li>
              )}
            </ul>
            <div className="panel-wrpr">
              <div
                id="showOverview"
                className={isOverview ? "tab-info active" : "tab-info d-none"}
              >
                <div className="info animated fadeInUp fast">
                  <div className="banner-preview-info m-0 animated fadeIn">
                    <div className="row">
                      <div className="col-md-6 mt-4">
                        <h5 className="match">
                          <span className="age">{movie.rating}</span>
                          <span className="duration">
                            {getVideoDuration(movie.video_duration)}
                          </span>
                        </h5>
                        <div
                          onMouseEnter={(e) => (e.target.style.color = "white")}
                          onMouseLeave={(e) => (e.target.style.color = "#999")}
                          style={{ cursor: "pointer" }}
                          className="content__description"
                          onClick={showDetails}
                        >
                          {movie.synopsis != null && movie.synopsis.length > 250
                            ? movie.synopsis.substring(0, 250) + "..."
                            : movie.synopsis
                            ? movie.synopsis
                            : ""}
                        </div>
                        <div className="row ml-1 mt-4">
                          <button
                            type="button"
                            className="btn-play"
                            onClick={onPlayVideo}
                          >
                            <img
                              src={playArrow}
                              alt=""
                              className="d-inline-block play-btn"
                            />
                            <span className="label">
                              Play <span className="dots"></span>
                            </span>
                          </button>
                          {likeFlag == 1 ? (
                            <span
                              title="Un-Like this video"
                              className="li-like-video list-inline-item clearfix"
                            >
                              <img
                                src={likedIcon}
                                data-like="no"
                                className="d-block like-dislike-btn"
                                alt="Like"
                                onClick={unLikeVideo}
                              />
                            </span>
                          ) : disLikeFlag == 1 ? (
                            <span
                              title="Un-Dislike this video"
                              className="li-dislike-video list-inline-item clearfix"
                            >
                              <img
                                src={disLikedIcon}
                                data-like="no"
                                className="d-block like-dislike-btn"
                                alt=""
                                onClick={unDislikeVideo}
                              />
                            </span>
                          ) : (
                            <>
                              <span
                                title="Like this video"
                                className="li-like-video list-inline-item clearfix"
                              >
                                <img
                                  src={likeIcon}
                                  data-like="no"
                                  className="d-block like-dislike-btn"
                                  alt="Like"
                                  onClick={likeVideo}
                                />
                              </span>
                              <span
                                title="Dislike this video"
                                className="li-dislike-video list-inline-item clearfix"
                              >
                                <img
                                  src={disLikeIcon}
                                  data-like="no"
                                  className="d-block like-dislike-btn"
                                  alt=""
                                  onClick={dislikeVideo}
                                />
                              </span>
                            </>
                          )}
                        </div>
                        <div className="row ml-1 mt-3">
                          {myListFlag != 1 ? (
                            <button
                              type="button"
                              className="btn-mylist"
                              onClick={myListAdd}
                            >
                              <img
                                src={addMyList}
                                alt=""
                                className="d-inline-block plus-btn"
                              />
                              <span className="label">
                                My List <span className="dots"></span>
                              </span>
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn-mylist"
                              onClick={myListRemove}
                            >
                              <img
                                src={removeMyList}
                                alt=""
                                className="d-inline-block plus-btn"
                              />
                              <span className="label">
                                Added <span className="dots"></span>
                              </span>
                            </button>
                          )}
                        </div>
                        <div className="clearfix"></div>
                        <div className="row ml-1 mt-3">
                          {payPerView != undefined ? (
                            <span>
                              <button
                                className="content__blink_badge"
                                onClick={onPlayVideo}
                                data-toggle="tooltip"
                                title={`BUY-1yr: ${payPerView.symbol} ${payPerView.price}`}
                              >
                                Buy
                              </button>
                            </span>
                          ) : null}
                          {rental != undefined ? (
                            <span>
                              <button
                                className="content__blink_badge1"
                                onClick={onPlayVideo}
                                data-toggle="tooltip"
                                title={`RENT-1wk: ${rental.symbol} ${rental.price}`}
                              >
                                Rent
                              </button>
                            </span>
                          ) : null}
                          {yearly != undefined && monthly == undefined ? (
                            <span>
                              <button
                                className="content__blink_badge2"
                                onClick={onPlayVideo}
                                data-toggle="tooltip"
                                title={`ALL TITLES/Year:  ${yearly.symbol} ${yearly.price}`}
                              >
                                Subscribe
                              </button>
                            </span>
                          ) : null}
                          {monthly != undefined && yearly == undefined ? (
                            <span>
                              <button
                                className="content__blink_badge2"
                                onClick={onPlayVideo}
                                data-toggle="tooltip"
                                title={`ALL TITLES/Month:  ${monthly.symbol} ${monthly.price}`}
                              >
                                Subscribe
                              </button>
                            </span>
                          ) : null}
                          {yearly != undefined && monthly != undefined ? (
                            <span>
                              <button
                                className="content__blink_badge2"
                                onClick={onPlayVideo}
                                data-toggle="tooltip"
                                title={`ALL TITLES/Year:  ${yearly.symbol} ${yearly.price}\nALL TITLES/Month: ${monthly.symbol} ${monthly.price}`}
                              >
                                Subscribe
                              </button>
                            </span>
                          ) : null}
                        </div>
                        <p className="synopsis mt-4 pl-0 col-md-10">
                          {/* {movie.show_cast && (
                            <>
                              <span>
                                <strong>Starring:</strong> {movie.show_cast}
                              </span>
                              <br />
                            </>
                          )} */}
                          {movie.category_names && (
                            <>
                              <span>
                                <strong>Genres:</strong> {movie.category_names}
                              </span>
                              <br />
                            </>
                          )}
                          {movie.director && (
                            <>
                              <span>
                                <strong>Director:</strong> {movie.director}
                              </span>
                              <br />
                            </>
                          )}
                          {movie.producer && (
                            <>
                              <span>
                                <strong>Producer:</strong> {movie.producer}
                              </span>
                              <br />
                            </>
                          )}
                        </p>
                      </div>
                      <div className="col-md-6">
                        {getDeviceType() != "mobile" &&
                          (isMute ? (
                            <img
                              src={mute}
                              className="d-block expandMute content__expandMute"
                              alt="Sound"
                              onClick={onMute}
                            ></img>
                          ) : (
                            <img
                              src={unmute}
                              className="d-block expandMute content__expandMute"
                              alt="Sound"
                              onClick={onMute}
                            ></img>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="showEpisode"
                className={isEpisode ? "tab-info active" : "tab-info d-none"}
              >
                <div className="container-fluid mt-5 mb-5 animated fadeInUp fast">
                  <div className="row episodes-row">
                    <div className="col-md-12">
                      <div className="clearfix"></div>
                      {isEpisode &&
                        apiResponse.videos.length > 0 &&
                        !path.startsWith("/search") && (
                          <EpiosdeSlider index={index} type={"episode"}>
                            {apiResponse.videos.map((show) => (
                              <EpiosdeSlider.Item
                                movie={show}
                                index={index}
                                showId={movie.show_id}
                                key={show.video_id}
                                type={"episode"}
                              ></EpiosdeSlider.Item>
                            ))}
                          </EpiosdeSlider>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="showTrailer"
                className={isTrailer ? "tab-info active" : "tab-info d-none"}
              >
                <div className="container-fluid mt-5 mb-5 animated fadeInUp fast">
                  <div className="row">
                    <EpiosdeSlider index={index} type={"trailor"}>
                      <EpiosdeSlider.Item
                        movie={movie}
                        index={index}
                        showId={movie.show_id}
                        type={"trailor"}
                      ></EpiosdeSlider.Item>
                    </EpiosdeSlider>
                    {/* <div className="col-md-12">
                      <img
                        src="img/movie-logo.png"
                        alt=""
                        className="d-block logo-episode"
                      />
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-3 col-sm-6">
                      <div className="thumbnail-episode">
                        <div className="thumbnail-mask"></div>
                        <div className="episodeNumber noProgress">
                          <a
                            href="#"
                            className="episode-play-thumb d-none"
                            title="play"
                          ></a>
                        </div>
                      </div>
                      <p className="episode-heading trailer-episode-heading">
                        <span className="title-info">
                          Trailer: The Pharmacist
                        </span>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                id="showDetails"
                className={isDetails ? "tab-info active" : "tab-info d-none"}
              >
                <div className="container-fluid animated fadeInUp fast">
                  <div className="row">
                    <div className="col-md-3">
                      <ul className="list-unstyled list-tab">
                        <li className="listLabel">Show Name</li>
                        <li>
                          <a href="#">{movie.show_name}</a>
                        </li>
                        <li>
                          <span className="inner-small-head">Description</span>
                        </li>
                        <li>
                          <a href="#">{movie.synopsis}</a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-3">
                      <ul className="list-unstyled list-tab">
                        {movie.show_cast && (
                          <li className="listLabel">
                            <span>Cast</span>
                          </li>
                        )}
                        {movie.show_cast && (
                          <li>
                            <a href="#">{movie.show_cast}</a>
                          </li>
                        )}
                        {movie.director && (
                          <li>
                            <span className="inner-small-head">Director</span>
                          </li>
                        )}
                        {movie.director && (
                          <li>
                            <a href="#">{movie.director}</a>
                          </li>
                        )}
                      </ul>
                    </div>
                    {movie.category_names && (
                      <div className="col-md-3">
                        <h4 className="listLabel">Genres</h4>
                        <ul className="list-unstyled list-tab">
                          <li>
                            <a href="#">{movie.category_names}</a>
                          </li>
                        </ul>
                      </div>
                    )}
                    {movie.rating && (
                      <div className="col-md-3">
                        <h4 className="listLabel">Maturity Ratings</h4>
                        <span className="maturity-rating">
                          <a href="#" className="maturity-number">
                            {movie.rating}
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!path.startsWith("/showDetails") && (
          <button
            className="content__close"
            style={{ zIndex: "5" }}
            onClick={onCloseClick}
          >
            <IconCross />
          </button>
        )}
        {getDeviceType() == "mobile" &&
          movie.teaser != null &&
          isOverview &&
          (isMute ? (
            <img
              src={mute}
              className="d-block expandMute content__expandMute content__close expandMuteAdj"
              alt="Sound"
              onClick={onMute}
            ></img>
          ) : (
            <img
              src={unmute}
              className="d-block expandMute content__expandMute content__close expandMuteAdj"
              alt="Sound"
              onClick={onMute}
            ></img>
          ))}
        )
      </div>
    </div>
  );
};

export default Content;
