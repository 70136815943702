import React, { useState, useEffect } from "react";
import $ from "jquery";
import jQuery from "jquery";
import { useHistory } from 'react-router-dom';

const WheelCarousel = ({ items }) => {
  const history = useHistory();
  var sepMultiplier = 0.75;
  var mainCarousel;

  useEffect(() => {
    mainCarousel = $("#carousel").waterwheelCarousel({
      opacityMultiplier: 1,
      speed: 800,
      autoPlay: 500,
      sizeMultiplier: 0.8,
      separationMultiplier: sepMultiplier,
      separation: 140,
      horizonOffset: -37,
      horizonOffsetMultiplier: 0.8,
      imageNav: false,
      linkHandling: 0,
      flankingItems: 4,

      movedToCenter: function ($newCenterItem) {
        // var imageUrl = $newCenterItem.attr("id");
        // var caption = document
        //   .getElementById(imageUrl)
        //   .getAttribute("data-caption");
        // document.getElementById("posterCaption").innerHTML = caption;
        // var imageID = $newCenterItem.attr("caption");
        // $("#" + imageID + "-information").show();
      },
    });
  }, []);

  const onItemClick = (item) => {
    history.push({
      pathname: '/showDetails',
      search: '?id=' + item.show_id,
    });
  };

  return (
    <>
      <div id="carousel">
        {items.map((item, index) => {
          return (
            <>
              <div className="slide" key = {index}>
                <span>
                  <img
                    className="slide"
                    id={"image" + (index + 1)}
                    style={{ cursor: "pointer",paddingLeft:"1%" }}
                    height="290px"
                    width="200px"
                    src={
                      "https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/" +
                      item.banner
                    }
                    onClick={() => onItemClick(item)}
                  />
                </span>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};
export default WheelCarousel;
