import React, { useState, useEffect } from "react";
import Slider from "../NetflixSlider";
import { service } from '../../Network/service';

const NewArrivals = (props) => {

  const [moreRows, setMoreRows] = useState([]);
  const [isSetMore, setIsSetMore] = useState(false);
  const [rowItemsCount, setRowItemsCount] = useState(6);
  useEffect(() => {
    moreRows.push(
      <>
        <h3 className="section-title">New Arrivals</h3>
      </>
    );
    if (!isSetMore) {
      let apiResponse = null;
      service.getRecentlyAddedShows().then(response => {
        if (response.success === true) {
          apiResponse = response;
          let rows = [];
          let rowCount = 1;
          let count = apiResponse.data.length / rowItemsCount;
          if (apiResponse.data.length % rowItemsCount == 0) {
            rowCount = count;
          } else {
            rowCount = count + 1;
          }
          let tempLen = 0;
          for (let i = 1; i <= rowCount; i++) {
            if (tempLen + 1 < apiResponse.data.length) {
              rows.push(i * rowItemsCount);
            } else {
              rows.push(apiResponse.data.length);
            }
            tempLen += rowItemsCount;
          }
          let indexBigin = 0;
          rows.map((item, index) => {
            moreRows.push(
              <>
                <section className="cat">
                  <div style = {{marginLeft: "40px"}} className="slider-frame">
                    <Slider index={index} key={indexBigin}>
                      {apiResponse.data.slice(indexBigin, item).map((show) => (
                        <Slider.Item
                          movie={show}
                          index={index}
                          key={show.show_id}
                        ></Slider.Item>
                      ))}
                    </Slider>
                  </div>
                </section>
              </>
            );
            indexBigin += rowItemsCount;
          });
          setIsSetMore(true);
        }
      });
    }
  }, []);

  return <>{moreRows}
 
  </>;
};
export default NewArrivals;
