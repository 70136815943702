import React, { useEffect, useState ,useRef} from "react";
import { useHistory } from "react-router-dom";
import Slider from "../NetflixSlider";
let firstClientX, clientX;

const CategoryVideos = ({ categoryDetails, index }) => {
  let history = useHistory();
  const containerRef = useRef();

  let path = window.location.pathname;
  path = path.length == 1 ? "/home" : path;
  const [count, setCount] = useState(0);
  useEffect(() => {

    if (categoryDetails.shows) {
      if (path.startsWith("/movies")) {
        setCount(
          categoryDetails.shows.filter((item) => item.single_video == 1).length
        );
      } else if (path.startsWith("/tvShows")) {
        setCount(
          categoryDetails.shows.filter((item) => item.single_video == 0).length
        );
      } else {
        setCount(categoryDetails.shows.length);
      }
    }

    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  }, []);



  const preventTouch = e => {
    const minValue = 5; // threshold
  
    clientX = e.touches[0].clientX - firstClientX;
  
    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(clientX) > minValue) {
      if(e.cancelable){
        e.preventDefault();
        e.returnValue = false;
    
        return false;
      }
     
    }
  };
  
  const touchStart = e => {
    firstClientX = e.touches[0].clientX;
  };

  

  const onMoreClick = (e) => {
    if (categoryDetails.category_id == "191") {
      history.push("/newArrivals");
    } else
      history.push({
        pathname: "/more",
        search:
          "?catId=" +
          categoryDetails.category_id +
          "&path=" +
          path.split("/")[1],
        state: { catName: categoryDetails.category_name },
      });
  };
  return (
    <section className="cat">
      <h3 className="section-title">{categoryDetails.category_name + " "}</h3>
      {count > 7 && categoryDetails.category_id != 190 && (
        <span className="more moreViewAdj" onClick={onMoreClick}>
          More &gt;&gt;
        </span>
      )}
      <div className="slider-frame">
        <div ref={containerRef}>
          <Slider index={index} id="carousel">
            {path.startsWith("/movies")
              ? categoryDetails.shows
                  .filter((item) => item.single_video == 1)
                  .map((show) => (
                    <Slider.Item
                      movie={show}
                      index={index}
                      key={show.show_id}
                    ></Slider.Item>
                  ))
              : path.startsWith("/tvShows")
              ? categoryDetails.shows
                  .filter((item) => item.single_video == 0)
                  .map((show) => (
                    <Slider.Item
                      movie={show}
                      index={index}
                      key={show.show_id}
                    ></Slider.Item>
                  ))
              : categoryDetails.shows.map((show) => (
                  <Slider.Item
                    movie={show}
                    index={index}
                    key={show.show_id}
                  ></Slider.Item>
                ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default CategoryVideos;
