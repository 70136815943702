import React, { useState, useEffect } from "react";
import video from "../../video/video.mp4";
import mute from "../../img/icon-mute.png";
import Banner from "../Banner/Banner";
import Main from "../Main/Main";
import { getDeviceType } from '../../Utils/utils';
const Home = () => {
  let path = window.location.pathname;
  path = path.length == 1 ? '/home' : path;
  useEffect(() => {
   
  }, []);

  return (
    <>
      {(path.startsWith("/home") || path == "/") && ( getDeviceType() != 'mobile') && <Banner />}
      <Main />
    </>
  );
};
export default Home;
