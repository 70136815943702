import React, { Component } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import { Route, Redirect } from 'react-router-dom';
import Home from '../components/Home/Home';
import Header from '../components/Basic/Header';
import Footer from '../components/Basic/Footer';
import More from '../components/More/More';
import Search from '../components/Search/Search';
import VideoDetails from '../components/VideoDetails/VideoDetails';
import NewArrivals from '../components/NewArrivals/NewArrivals';
import WatchList from '../components/WatchList/WatchList';
import MyVideos from '../components/MyVideos/MyVideos';
import SignIn from '../components/SignIn/SignIn';
import Register from '../components/Register/Register';
import SubscriptionList from '../components/SubscriptionList/SubscriptionList';
import Payment from '../components/Payment/Payment';
import Error from '../components/Payment/Error';
import Succes from '../components/Payment/Succes';
import Live from '../components/Live/Live';
import MySubscription from '../components/MySubscription/MySubscription';
import ContactUs from "../components/ContactSupport/ContactSupport";
import TermsOfUse from "../components/TermsOfUse/TermsOfUse";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import Account from '../components/Account/Account';
import ManageDevice from '../components/ManageDevice/ManageDevice';
import BilingActivity from '../components/BilingActivity/BilingActivity';
import Signout from '../components/Signout/Signout';
import ChangePassword from '../components/ChangePassword/changePassword';
import Activate from '../components/Activate/Activate';
import AndroidSuccess from '../components/Payment/AndroidSuccess';
import AndroidError from '../components/Payment/AndroidError';
import ShowDetails from '../components/ShowDetails/ShowDetails';
import Contact from "../components/Contact/Contact";
import AboutUs from "../components/AboutUs/AboutUs";
import NotFound from '../components/NotFound/NotFound';
const Routes = () => {
    const path = window.location.pathname;
    const isAndroid = useSelector((state) => state.isAndroid);
    return (
        <BrowserRouter>
            {!isAndroid && ( <Header />)}
            <div className="closeMenuWrapper">
                <Switch>
                    <Route exact path="/"> <Redirect to="/home" /> </Route>
                    {/* <PublicRoutes restricted={true} path="/" exact component={Home} /> */}
                    <PublicRoutes path="/home" exact component={Home} />
                    <PublicRoutes path="/movies" exact component={Home} />
                    <PublicRoutes path="/tvShows" exact component={Home} />
                    <PublicRoutes path="/newArrivals" exact component={NewArrivals} />
                    <PrivateRoutes path="/watchList" exact component={WatchList} />
                    <PrivateRoutes path="/myVideos" exact component={MyVideos} />
                    <PublicRoutes path="/more" exact component={More} />
                    <PublicRoutes path="/search" exact component={Search} />
                    <PublicRoutes path="/videoDetails" exact component={VideoDetails} />
                    <PublicRoutes path="/register" exact component={Register} />
                    <PublicRoutes path="/signIn" exact component={SignIn} />
                    <PublicRoutes path="/subscription" exact component={SubscriptionList} />
                    <PrivateRoutes path="/payment" exact component={Payment} />
                    <PrivateRoutes path="/success" exact component={Succes} />
                    <PrivateRoutes path="/error" exact component={Error} />
                    <PublicRoutes path="/live" exact component={Live} />
                    <PrivateRoutes path="/mySubscription" exact component={MySubscription} />
                    <PublicRoutes path="/termOfUse" exact component={TermsOfUse} />
                    <PublicRoutes path="/privacyPolicy" exact component={PrivacyPolicy} />
                    <PublicRoutes path="/contactUs" exact component={ContactUs} />
                    <PrivateRoutes path="/account" exact component={Account} />
                    <PrivateRoutes path="/manageDevice" exact component={ManageDevice} />
                    <PrivateRoutes path="/bilingActivity" exact component={BilingActivity} />
                    <PrivateRoutes path="/signout" exact component={Signout} />
                    <PrivateRoutes path="/changePassword" exact component={ChangePassword} />
                    <PublicRoutes path="/tv" exact component={Activate} />
                    <PrivateRoutes path="/webviewsuccess" exact component={AndroidSuccess} />
                    <PrivateRoutes path="/failed" exact component={AndroidError} />
                    <PublicRoutes path="/showDetails" exact component={ShowDetails} />
                    <PublicRoutes path="/aboutUs" exact component={AboutUs} />
                    <PublicRoutes path="/contact" exact component={Contact} />
                    <PublicRoutes path="/notFound" exact component={NotFound} />
                    <Route path="/404" component={NotFound} /> <Redirect to="/404" />
                </Switch>
            </div>
            <Footer/>
        </BrowserRouter>
    );
};
export default Routes;