import React, { useState, useEffect } from 'react';
import { Link, useHistory, Redirect } from 'react-router-dom';
import Footer from '../Basic/Footer';

const Contact = () => {
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
        <div className="menuCloseJS closeMenuWrapper">
            <div id="content" className="site-content page-id-41">
                <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                        <article id="post-41" className="post-41 page type-page status-publish hentry">
                            <div className="entry-content">
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className=" vc_custom_1580990739237 fantastic-header ml-3">
                                                    <div id="main-bg" className="main-bg"></div>
                                                    <div className="header-content">
                                                        <h1>Contact Us</h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element  normal w_780  vc_custom_1580991299191 container fantastic-content">
                                                    <div className="wpb_wrapper">
                                                        <h3 style={{ textAlign: 'center', color: '#fff' }}>Get in Touch</h3>
                                                        <p>Review the contact options below to ensure we get your information to the right member of our team.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid container w_870 middle-border vc_custom_1580992849222">
                                   
                                    <div className="wpb_column vc_column_container vc_col-sm-6">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element  small_14 normal  container fantastic-content">
                                                    <div className="wpb_wrapper">
                                                        <h6 style={{ textAlign: 'center',color: '#fff' }}>Submit Your Content</h6>
                                                        <p style={{ textAlign: 'center' }}>Follow the instructions&nbsp;
                                                        <span style={{cursor:'pointer',}}onClick={() => {
                                                                history.push(
                                                                    { pathname: '/contactUs' }
                                                                )
                                                            }} className="contact-us-touch-link" title="Content Submission"><u>here</u></span>.</p>
                                                        <div id="gtx-trans" style={{ position: 'absolute', left: '361px', top: '39.9375px' }}>
                                                            <div className="gtx-trans-icon"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="wpb_text_column wpb_content_element  small_14 normal  vc_custom_1587428376968 container fantastic-content">
                                                    <div className="wpb_wrapper">
                                                        <h6 style={{ textAlign: 'center',color: '#fff' }}>Advertise With Us</h6> </div>
                                                </div>
                                                <div className="modal-popup-box" data-bodybg="rgba(0,0,0,0.39)" style={{ textAlign: 'center' }}>
                                                    <button className="model-popup-btn popup-176" data-id="popup-176" onClick={() => {
                                                        history.push(
                                                            { pathname: '/contactUs' }
                                                        )
                                                    }} style={{ color: '#707c86', borderRadius: '2px', fontSize: '14px', padding: '14px 61px}' }}>
                                                        <i style={{ paddingRight: '5px' }} className="fa " aria-hidden="true"> </i> Get in Touch With a Sales Rep </button>
                                                </div>
                                                {/* <style>
                                                    .modal-popup-box .popup-176:hover {
                                                        color: #26262d !important;
                                                        background: !important;
                                                    }
                                                    </style> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element  b-color-border white small  vc_custom_1596100857685 container fantastic-text-banner">
                                                    <div className="wpb_wrapper">
                                                        <h4>Need additional help?</h4>
                                                        <p>Contact our support team for any other Fantastic-Entertainment questions.</p>
                                                        <a className="btn support-link"
                                                            onClick={() => {
                                                                history.push(
                                                                    { pathname: '/contactUs' }
                                                                )
                                                            }}
                                                            style={{backgroundColor: '#e50914', color: '#fff'}}
                                                            title="Support" target="">
                                                            <span>Support</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner">
                                            <div className="wpb_wrapper">
                                                <div className="wpb_text_column wpb_content_element    container fantastic-footer">
                                                    <div className="wpb_wrapper">
                                                        <hr />
                                                        <p>The provided agreements on Fantastic-Entertainment are for informational purposes only and do not constitute legal advice.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vc_row wpb_row vc_row-fluid">
                                    <div className="wpb_column vc_column_container vc_col-sm-12">
                                        <div className="vc_column-inner vc_custom_1578648045732">
                                            <div className="wpb_wrapper"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </main>
                </div>
            </div>
        </div>
        </>
    );
}
export default Contact;
