import React, { useState, useEffect } from 'react';
import SubscriptionContainer from './SubscriptionContainer';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { service } from '../../Network/service';
const SubscriptionList = (state) => {
    const [subscription, setSubscription] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const [androidData, setAndroidData] = useState('');
    const [isAndroid, setIsAndroid] = useState(false);
    const [antkn, setAntkn] = useState('');
    let urlParams = new URLSearchParams(window.location.search);
    useEffect(() => {
        var selectedVideoId = state.location.state != undefined ? state.location.state.videoData : null;
        var singleObj = [];
        setAntkn(urlParams.get('antkn'));
        let androidToken = urlParams.get('antkn');
        if (androidToken) {
            // localStorage.clear();
            dispatch({ type: "SET_ANDROID"});
            selectedVideoId = urlParams.get('vd');
            service.androidTokeDecode(androidToken).then(response => {
                if(response.success == true) {
                localStorage.setItem('access-token', androidToken);
                service.setCookie('userId', response.data[0].user_id, 15);
                localStorage.setItem('userName', response.data[0].first_name);
                localStorage.setItem('isAndroid', 'true');
                service.setCookie('isLoggedIn', 'true', 15);
                localStorage.setItem('isLoggedIn', 'true');
                setAndroidData(response.data);
                setIsAndroid(true);
                } else {
                    // setAndroidData1(JSON.stringify(response))
                }
            })
        }
        if (selectedVideoId) {
            service.videoSubscription(selectedVideoId).then(response => {
                var data = response.data;
                if (data != undefined)
                    data.map((item, index) => {
                        singleObj.push(item);
                    })
                setSubscription(singleObj);
                let videoPath = localStorage.getItem('videoPath');
                let userSub = service.getCookie("userId");
                if (videoPath != null && userSub != 280 && userSub != null) {
                    service.userSubscription(userSub).then((useResponse) => {
                        var userData = useResponse.data;
                        if (userData.length != 0) {
                            let isSubscribed = false;
                            data.map(function (subscription, index) {

                                let subscribedVideo = userData.filter(
                                    (e) => e.sub_id == subscription.publisher_subscription_id
                                );
                                if (subscribedVideo.length > 0) {
                                    isSubscribed = true;
                                }
                                if (isSubscribed && data.length == index + 1) {
                                    let showId = service.getCookie('showId');
                                    if(showId) {
                                    history.push({
                                        pathname: '/showDetails',
                                        search: '?id=' + showId,
                                    })
                                } else
                                    history.push(videoPath);
                                }
                            });
                        }
                    });
                }
            })
        } else {
            service.publisherSubscription().then(response => {
                var data = response.data;
                if (data != undefined)
                    data.map((item, index) => {
                        singleObj.push(item);
                    })
                setSubscription(singleObj);
            })
        }



    }, []);


    return (
        <div className="pageWrapper searchPageMain">
            <div className="topContainer" style={{ backgroundColor: '#000', marginTop: '-80px' }}>
                <div className="homepageWrapper menuCloseJS closeMenuWrapper">
                    <div className="allCategoryContainer">
                        <div key={1}>
                            <SubscriptionContainer param={subscription}
                                androidData={androidData}
                                isAndroid={isAndroid}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
export default SubscriptionList;