import React, { useState, useEffect } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import Footer from '../Basic/Footer';
import LiveContainer from './LiveContainer';
import LiveSchedule from './LiveSchedule';

const Live = () => {

    return (
        <>
        <div className="pageWrapper searchPageMain">
        <div className="topContainer" style={{marginTop: '-80px', backgroundColor: '#000'}}>
            <div className="homepageWrapper menuCloseJS closeMenuWrapper">
                
                <LiveContainer />
                <LiveSchedule />
            
            </div>
        </div>
    </div>
    </>
    );
}
export default Live;