
import React, { useState, useEffect } from "react";
import CategoryVideos from "../CategoryVideos/CategoryVideos";
import { service } from '../../Network/service';
import $ from "jquery";
import Footer from "../Basic/Footer";

const Main = () => {
  const [catRows, setCatRows] = useState([]);
  const [newArrivalRows, setNewArrivalRows] = useState([]);
  const [apiResponse, setApiResponse] = useState([]);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [apiInitialResponse, setApiInitialResponse] = useState([]);
  let path = window.location.pathname;
  path = path.length == 1 ? '/home' : path;
  useEffect(() => {
    var singleObj = [];
    service.getshowsbyCategory().then(response => {
      if (response.success === true && response.data.length > 0) {
        setIsLoadMore(true);
        setApiInitialResponse(response.data);
        setApiResponse(response.data.slice(0, 4));
      }
    });
    setTimeout(function () {
      service.freeVideos().then(response => {
        if (response.success === true) {
          let freeVideo = {};
          freeVideo.category_name = "Watch for Free";
          freeVideo.category_id = "190";
          freeVideo.shows = response.data;
          let temp = [];
          let catRow = <><CategoryVideos
            categoryDetails={freeVideo}
            index={99}
            key={190}
          /></>

          temp.push(catRow);
          setCatRows(temp);
        }
      });
      service.getRecentlyAddedShows().then(response => {
        if (response.success === true) {
          let freeVideo = {};
          freeVideo.category_name = "New Arrivals";
          freeVideo.category_id = "191";
          freeVideo.shows = response.data;
          let temp = [];
          let catRow = <><CategoryVideos
            categoryDetails={freeVideo}
            index={98}
            key={191}
          /></>

          temp.push(catRow);
          setNewArrivalRows(temp);
        }
      });
      $(document).click(function (event) {
        var clickover = $(event.target);
        var clickoverParent = clickover.parent();
        var $navbar = $("#navbarSupportedContent");
        var _opened = $navbar.hasClass("show");
        if (_opened === true && !clickover.hasClass("navbar-toggler") && !clickoverParent.hasClass("navbar-toggler")) {
          $navbar.removeClass('show');
        } else if (clickover.hasClass("navbar-toggler") || clickoverParent.hasClass("navbar-toggler")) {
          if(_opened == true) {
            $navbar.removeClass('show');
          } else
          $navbar.addClass('show');
        }
      });
    }, 500)
    let scronPosition = localStorage.getItem('scrollPosition' + path);
    if (scronPosition) {
      setTimeout(function () {
        scronPosition = JSON.parse(scronPosition);
        if (localStorage.getItem('loadmoreAxis')) {
          let loadmoreAxis = Number(localStorage.getItem('loadmoreAxis'));
          let playBtnY = Number(scronPosition[1]);
          if (playBtnY > loadmoreAxis) {
            $('#loadBtn').click();
            // loadMore();
          }
        }
        setTimeout(function () {
          window.scrollTo(scronPosition[0], scronPosition[1]);
          localStorage.removeItem('scrollPosition' + path);
        }, 700)

      }, 1500);
    } else {
      window.scrollTo(0, 0);
    }

  }, []);
  const loadMore = () => {
    var supportPageOffset = window.pageXOffset !== undefined;
    var isCSS1Compat = (document.compatMode || "") === "CSS1Compat";

    var y = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
        ? document.documentElement.scrollTop
        : document.body.scrollTop;
    if (localStorage.getItem('loadmoreAxis') == null)
      localStorage.setItem('loadmoreAxis', y);
    setIsLoadMore(false);
    setApiResponse(apiInitialResponse);
  }
  return (
    <>
      <main 
      style={{ marginRight: '20px', marginLeft: '40px' }}
      >
        {
          !path.startsWith("/movies") && !path.startsWith("/tvShows") &&
          (catRows)
        }
        {
          !path.startsWith("/movies") && !path.startsWith("/tvShows") &&
          (newArrivalRows)
        }
        {apiResponse.map((item, index) => {
          let count = 0;
          if (path.startsWith("/movies")) {
            count = item.shows.filter((item) => item.single_video == 1);
          } else if (path.startsWith("/tvShows")) {
            count = item.shows.filter((item) => item.single_video == 0);
          } else {
            count = -1;
          }
          if (count != 0) {
            return (
              <CategoryVideos
                categoryDetails={item}
                index={index}
                key={item.category_id}
              />
            );
          } else {
            return null;
          }
        })}
        {isLoadMore && (<div className="row d-flex justify-content-center">
          <button id="loadBtn" className="button mb-4" type="button" onClick={loadMore}>
            <div className="buttonBg"></div>
            <div className="buttonContent">Load More</div>
          </button>
        </div>)}
      </main>
    </>
  );
};
export default Main;