import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { service } from '../../Network/service';
import { checkOperatingSystem } from '../../Utils/utils';
import { Link } from "react-router-dom";
import avatar from "../../img/face.png";
import logo from "../../img/logo.png";
import appLogo from "../../img/appLogo.png";
import { useSelector } from 'react-redux';

const Header = () => {
  let history = useHistory();
  const updateHeader = useSelector((state) => state.updateHeader);
  let path = window.location.pathname;
  path = path.length == 1 ? '/home' : path;
  const [downloadHoverStyle, setDownloadHoverStyle] = useState([]);
  const [downloadHover, setDownloadHover] = useState(false);
  const [deviceType, setDeviceType] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchkey, setSearchkey] = useState("");

  const [scrolled, setScrolled] = React.useState(false);
  let device = checkOperatingSystem();

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
      if (!(device === 'none' || device === 'window' || device === 'mac')) {
        setDownloadHover(false);
      }
    } else {
      setScrolled(false);
      if (!(device === 'none' || device === 'window' || device === 'mac')) {
        setDownloadHover(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    window.addEventListener('click', function(e){   
      if (document.getElementById('togglerIcon').contains(e.target)){
        // console.log('inside the div')
      } else{
        document.getElementById("navBarIconClick").classList.remove("show");
      }
    });

    setDeviceType(device);
    if (device === 'none' || device === 'window' || device === 'mac') {
      setDownloadHover(false);
      setDownloadHoverStyle('0px');
    } else {
      setDownloadHoverStyle('80px');
      setDownloadHover(true);

    }
  }, []);

  useEffect(() => {
  }, [updateHeader]);

  const deviceOpenFunction = () => {
    if (deviceType === 'android') {
      window.location = "https://play.google.com/store/apps/details?id=com.fe.android_mobile";
    } else if (deviceType === 'iPhone') {
      window.location = "https://apps.apple.com/in/app/fantastic-entertainment/id1512715977";
    }
  }
  const onSearchOpen = (e) => {
    setIsSearch(true);
  };

  const onSearchClose = (e) => {
    setIsSearch(false);
    setSearchkey("");
    if (localStorage.getItem("previousPage"))
      history.push(localStorage.getItem("previousPage"))
  };

  const onSearchKeyChange = (e) => {
    e.preventDefault();
    if (!path.startsWith("/search")) {
      localStorage.setItem("previousPage", path);
    }
    setSearchkey(e.target.value);
    if (e.target.value.length > 0) {
      history.push({
        pathname: "/search",
        search: "?keyword=" + e.target.value,
      });
    } else {
      if (localStorage.getItem("previousPage"))
        history.push(localStorage.getItem("previousPage"))
    }
  };
  const functionLogout = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem('isLoggedIn');
    // dispatch({ type: "LOGOUT" });
    // setMouseHover(false);
    service.eraseCookie('userName');
    service.eraseCookie('userId');
    service.eraseCookie('userEmail');
    service.eraseCookie('subscriptionId');
    window.location.href = "/home";
  }
  const setHomePage = () => {
    history.push('/');
  }

  const navbarToggleIcon =()=>{
    var elem = document.getElementById("navBarIconClick").className;
    if(elem.includes("show") === false){
      document.getElementById("navBarIconClick").classList.add("show");
    }else{
      document.getElementById("navBarIconClick").classList.remove("show");
    }
  }
  return (
    <header>
      {
        downloadHover === true && !scrolled && path.startsWith('/home') &&
        <div className="headerMenu headerWhite headerGradient"
          style={{ backgroundColor: '#d9d7d7', marginTop: '-10px', padding: '0px', height: '100px' }}>
          <div className="container headerWrapper" >
            <img src={appLogo} style={{ cursor: 'pointer' }} width={40} />
            <div className="logosection" style={{ padding: '4px' }}>
              <div className="logoContain" style={{ width: '174px' }}>
                <span style={{ fontSize: '16px', fontWeight: '700', marginLeft: '4px' }}>Fantastic-Entertainment<br />
                  <span style={{ fontSize: '10px', fontWeight: '600' }} >Open in the Fantastic-Entertainment app</span>
                </span>
              </div>
            </div>
            <div>
              <div className="buttonopen" onClick={deviceOpenFunction}>OPEN</div>
            </div>
          </div>
        </div>
      }
      <div className={downloadHover && path.startsWith('/home') ? (scrolled ? "container-fluid scrolled headerAdj" : "container-fluid headerAdj") : (scrolled ? "container-fluid scrolled" : "container-fluid")}>
        <div className="row">
          <div className="col-md-2 col-sm-12">
            <h4 className="logo">
              <img src={logo} style={{ width: "125px", cursor: 'pointer' }} onClick={setHomePage} />
            </h4>
          </div>
          <div className="col-md-6 col-sm-9">
            <nav className="navbar navbar-expand-sm">
              <button
                onClick={navbarToggleIcon}
                className={downloadHover && path.startsWith('/home') ? "navbar-toggler headerAdj" : "navbar-toggler"}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="material-icons text-white" id="togglerIcon">more_vert</i>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navBarIconClick"
                //id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  <li
                    className={
                      path.startsWith("/home") || path == "/"
                        ? "nav-item active"
                        : "nav-item "
                    }
                  >
                    <a href="/home" className="nav-link pl-sm-0">
                      Home
                    </a>
                  </li>
                  <li
                    className={
                      path.startsWith("/tvShows")
                        ? "nav-item active"
                        : "nav-item "
                    }
                  >
                    <a href="/tvShows" className="nav-link">
                      TV Shows
                    </a>
                  </li>
                  <li
                    className={
                      path.startsWith("/movies")
                        ? "nav-item active"
                        : "nav-item "
                    }
                  >
                    <a href="/movies" className="nav-link">
                      Movies
                    </a>
                  </li>
                  {/* <li className={
                    path.startsWith("/newArrivals")
                      ? "nav-item active"
                      : "nav-item "
                  }>
                    <a className="nav-link" href="/newArrivals">
                      New Arrivals
                    </a>
                  </li> */}
                  <li className={
                    path.startsWith("/live")
                      ? "nav-item active"
                      : "nav-item "
                  }>
                    <a className="nav-link" href="/live">
                      Live
                    </a>
                  </li>
                  <li className={
                    path.startsWith("/tv")
                      ? "nav-item active"
                      : "nav-item "
                  }>
                    <a className="nav-link" href="/tv">
                      TV
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="col-md-4 col-sm-3">
            <ul className={downloadHover && path.startsWith('/home') ? "list-inline list-header  float-sm-right  position-relative headerAdj" : "list-inline list-header  float-sm-right m-0 position-relative"}>
              <li className="list-inline-item position-relative">
                <div
                  className={isSearch ? "search-wrpr" : "search-wrpr d-none"}
                >
                  <input
                    type="text"
                    className="search-box"
                    placeholder="Titles, Proples, Genres"
                    value={searchkey}
                    onChange={onSearchKeyChange}
                  />
                  <i
                    className="material-icons close-search"
                    onClick={onSearchClose}
                  >
                    close
                  </i>
                </div>
                <i
                  className={
                    isSearch
                      ? "material-icons search-icon icon d-none"
                      : "material-icons search-icon icon"
                  }
                  onClick={onSearchOpen}
                >
                  search
                </i>
              </li>
              <li className="list-inline-item">
                <div className="dropleft">
                  <img
                    src={avatar}
                    alt="User"
                    className="d-block logged-user"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div className="clearfix"></div>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/* <a className="dropdown-item" href="#">
                      Account
                    </a> */}
                    {
                      service.getCookie('isLoggedIn') == 'true' ?
                        (
                          <>
                            <a className="dropdown-item" href="#">{"Hi, " + localStorage.getItem('userName')}</a>
                            <a className="dropdown-item" href="/account">
                              Account Settings
                      </a>
                            <a className="dropdown-item" href="/watchList">
                              Watch List
                      </a>
                            <a className="dropdown-item" href="/myVideos">
                              My Videos
                      </a>
                            {/* <a className="dropdown-item" href="/mySubscription">
                              My Purchases
                      </a> */}
                            <a className="dropdown-item" onClick={functionLogout} href="#">
                              Signout
                     </a></>
                        ) : (
                          <><a className="dropdown-item" href="/signIn">
                            Sign In
                      </a>
                            <a className="dropdown-item" href="/register">
                              Register
                      </a>
                          </>
                        )
                    }
                    {/* <a className="dropdown-item" href="/privacyPolicy">
                      Privacy Policy
                    </a>
                    <a className="dropdown-item" href="/termOfUse">
                      T & C
                    </a>
                    <a className="dropdown-item" href="/contactUs">
                      Contact Us
                    </a> */}


                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="row footerSeperator">
          <div className="col col-12  footerLine" style={{ backgroundColor: '#272525', marginTop: '10px' }}></div>
        </div> */}
      </div>

    </header>
  );
};

export default Header;
